<template>
  <div class="riverlist">
    <h1 class="headtext">River Systems:</h1>

    <ul v-if="riverSystems && riverSystems.length" id="infinite-river-list">
      <li
        v-for="listrevir of riverSystems"
        v-on:click="getFacilities(listrevir)"
        v-bind:key="listrevir.id"
      >
        <a href="javascript:void(0)">
          <span
            v-bind:class="
              listrevir.alertCount > 0
                ? 'orange-alert'
                : listrevir.notificationCount > 0
                ? 'green-notification'
                : ''
            "
            >{{ listrevir.facilityCount }}
            <img src="../assets/images/wave-icon.png" alt="Systems"
          /></span>
          <p>
            <b>{{ listrevir.name }}</b>
            {{ listrevir.facilityCount }} Facilities
          </p>
        </a>
      </li>
    </ul>
    <div v-if="showAlert">
      <b-alert class="alert-custom" show variant="danger"
        >River systems not found</b-alert
      >
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import customInstance from "../config";

export default {
  name: "ListRiverSystem",
  data() {
    return {
      riverSystems: [],
      errors: [],
      showAlert: false,
      limit: 10,
      hasMoreRiver: false,
      pageRiver: 1,
      regionName: "",
    };
  },
  methods: {
    getRiverSytems: function () {
      let paramId = this.$route.params.regionSlug;
      this.hasMoreRiver = false;
      let params = {
        limit: this.limit,
        page: this.pageRiver,
      };
      customInstance
        .get("/riverSytems/" + paramId, { params })
        .then((response) => {
          if (response.data.status == 200) {
            this.riverSystems = this.riverSystems.concat(response.data.data);
            this.regionName = response.data.region.name;
            document.title =
              this.$route.params.regionSlug == "all"
                ? "List of Regions | SafeWaters by Brookfield Renewable US"
                : this.regionName +
                  " - Water Release Information | SafeWaters by Brookfield Renewable US";
            this.showAlert = false;
            this.riverSystemLoaded();
            if (this.riverSystems.length >= response.data.totalCount) {
              this.hasMoreRiver = false;
            } else {
              this.hasMoreRiver = true;
            }
          }
          if (response.data.status == 400) {
            this.riverSystems = [];
            this.showAlert = true;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    loadMoreRiverSystem() {
      if (this.hasMoreRiver) {
        this.pageRiver = this.pageRiver + 1;
        this.getRiverSytems();
      }
    },
    riverSystemLoaded() {
      this.$nextTick(() => {
        const listElm = document.querySelector("#infinite-river-list");
        listElm.addEventListener("scroll", (e) => {
          if (
            listElm.scrollTop + listElm.clientHeight >=
            listElm.scrollHeight
          ) {
            this.loadMoreRiverSystem();
          }
        });
      });
    },
    getFacilities: function (riverInfo) {
      this.$router.push(
        "/list/" + this.$route.params.regionSlug + "/" + riverInfo.slug
      );
    },
  },
  created() {
    this.getRiverSytems();
  },
  mounted() {},
  watch: {
    $route: function (to, from) {
      this.pageRiver = 1;
      this.riverSystems = [];
      this.errors = [];
      this.getRiverSytems();
    },
  },
};
</script>
