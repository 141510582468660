<template>
  <div
    id="cookie-law-info-bar"
    data-nosnippet="true"
    data-cli-style="cli-style-v2"
    style="
      background-color: rgb(255, 255, 255);
      color: rgb(51, 51, 51);
      font-family: inherit;
      bottom: 0px;
      position: fixed;
      display: block;
    "
    v-if="showCookie"
  >
    <span>
      <div class="cli-bar-container cli-style-v2">
        <div class="cli-bar-message">
          This website uses cookies, including third-party cookies. Please
          <router-link
            to="/term-cookie-policy"
            style="color: #509e2f; font-weight: 600"
            >read our cookie notice</router-link
          >
          for more information. By continuing to use this site, you agree to our
          use of cookies.<br />
        </div>
        <div class="cli-bar-btn_container">
          <a
            role="button"
            tabindex="0"
            data-cli_action="accept"
            id="cookie_action_close_header"
            class="medium cli-plugin-button cli-plugin-main-button cookie_action_close_header cli_action_button"
            style="
              display: inline-block;
              color: rgb(255, 255, 255);
              background-color: rgb(97, 162, 41);
              padding: 10px 15px;
            "
            @click="acceptCookieHandler()"
            >CONTINUE</a
          >
        </div>
      </div></span
    >
  </div>
</template>

<script>
export default {
  name: "CookiePopup",
  data() {
    return {
      showCookie: false,
    };
  },
  methods: {
    acceptCookieHandler() {
      this.showCookie = false;
      localStorage.setItem("cookieAcceptedFlag", true);
    },
  },
  created() {
    if (localStorage.getItem("cookieAcceptedFlag") === null) {
      this.showCookie = true;
    } else {
      this.showCookie = false;
    }
  },
};
</script>

<style scoped>
#cookie-law-info-bar[data-cli-style="cli-style-v2"] {
  padding: 14px 25px;
}

#cookie-law-info-bar {
  padding: 14px 25px;
  font-size: 15px;
  margin: 0 auto;
  padding: 12px 10px;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  z-index: 9999;
  left: 0px;
  font-weight: 300;
  box-shadow: 0 -1px 10px 0 rgb(172 171 171 / 30%);
}

.cli-style-v2 {
  font-size: 11pt;
  line-height: 18px;
  font-weight: normal;
}

.cli-bar-container {
  float: none;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.cli-style-v2 .cli-bar-message {
  width: 90%;
}

.cli-style-v2 .cli-bar-message {
  text-align: left;
}

.cli-bar-btn_container {
  margin-left: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-wrap: nowrap;
}

.cli-style-v2 .cli-bar-btn_container .cli-plugin-button {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
