<template>
  <div id="app">
    <header-component
      v-if="headerShow !== null && headerShow"
    ></header-component>
    <login></login>
    <router-view />
  </div>
</template>
<script>
import HeaderComponent from "./components/header.vue";
import login from "./components/login.vue";
import CookiePopup from "./components/CookiePopup.vue";
export default {
  components: {
    HeaderComponent,
    login,
    CookiePopup,
  },
  data() {
    return {
      headerShow: null,
    };
  },
  watch: {
    $route: function (to, from) {
      if (
        to.path.includes("public-safety") ||
        this.$route.path.includes("terraform")
      ) {
        this.headerShow = false;
      } else {
        this.headerShow = true;
      }
    },
  },
  mounted() {
    if (
      this.$route.path.includes("public-safety") ||
      this.$route.path.includes("terraform")
    ) {
      this.headerShow = false;
    } else {
      this.headerShow = true;
    }
  },
};
</script>

<style>
@import "./assets/css/style.css";
</style>