<template>
  <div class="safewaterBG1">
    <div class="SafetySection">
      <b-navbar toggleable="lg" type="dark" variant="info">
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav>
            <a
              ><img
                src="../assets/images/digital-ads/Terraform-logo.png"
                alt="brookfieldus"
                class="brookfieldLogoImage"
            /></a>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="brookfield-section">
              <h1>
                Terraform Power, a leader in clean energy, reminds you to stay
                safe!
              </h1>
            </div>
          </div>
        </div>
        <h6 class="StayHeading text">
          <h6 class="Heading">STAYING SAFE</h6>
          Our wind and solar facilities provide affordable clean energy to
          communities across the nation, and many are in scenic areas where you
          can enjoy all kinds of recreational activities. As you do, please
          remember:
          <ul class="mt-3">
            <li>
              Always keep a safe distance from wind turbines. They create air
              flow disruption that can affect drones, paragliders, and other
              small aircraft. Ice and other debris can fall from them and cause
              serious injuries.
            </li>
            <li>
              Wind and solar farms are active power generating facilities that
              operate at high voltages. Do not approach any equipment or
              transmission lines when near these facilities.
            </li>
          </ul>
          <p>
            Safety is important at all times. If you see an unsafe condition,
            please
            <a href="http://www.terraform.com/contact-us" target="_blank"
              >get in touch with us</a
            >
            using the
            <a href="http://www.terraform.com/contact-us" target="_blank"
              >contact form here</a
            >.
          </p>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "DigitalAds",
  components: { VueSlickCarousel },
  data() {
    return {
      sliderSetting: {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        swipeToSlide: true,
        centerPadding: "20px",
        initialSlide: 0,
        arrows: true,
      },

      staySafeCard: [
        {
          imageClass: "cardBgSecOne",
        },
        {
          imageClass: "cardBgSecTwo",
        },
        {
          imageClass: "cardBgSecThree",
        },
        {
          imageClass: "cardBgSecFour",
        },
        {
          imageClass: "cardBgSecFive",
        },
      ],
    };
  },
  mounted() {
    if (window.innerWidth < 585) {
      this.sliderSetting.slidesToShow = 1;
    } else if (window.innerWidth >= 585 && window.innerWidth < 850) {
      this.sliderSetting.slidesToShow = 2;
    } else if (window.innerWidth >= 850 && window.innerWidth <= 1280) {
      this.sliderSetting.slidesToShow = 3;
    } else if (window.innerWidth > 1280 && window.innerWidth <= 1600) {
      this.sliderSetting.slidesToShow = 4;
    } else if (window.innerWidth > 1600) {
      this.sliderSetting.slidesToShow = 5;
    }
  },
  methods: {
    flowInformationHandler() {
      this.$router.push({ path: "/" });
    },
    myEventHandler(e) {
      if (window.innerWidth < 585) {
        this.sliderSetting.slidesToShow = 1;
      } else if (window.innerWidth >= 585 && window.innerWidth < 850) {
        this.sliderSetting.slidesToShow = 2;
      } else if (window.innerWidth >= 850 && window.innerWidth <= 1280) {
        this.sliderSetting.slidesToShow = 3;
      } else if (window.innerWidth > 1280 && window.innerWidth <= 1600) {
        this.sliderSetting.slidesToShow = 4;
      } else if (window.innerWidth > 1600) {
        this.sliderSetting.slidesToShow = 5;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style>
.safewaterBG1 {
  background-image: url("../assets/images/digital-ads/Card-img6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.safewaterBG1 .SafetySection {
  width: calc(100% - 8%);
  margin: auto;
  padding-top: 10px;
  padding-bottom: 70px;
}
.safewaterBG1 .publicList {
  margin: 0 30px;
}
.safewaterBG1 .greatIcon svg {
  font-size: 24px;
  text-shadow: 0px 1px 10px #000;
  margin-left: 8px;
}

.safewaterBG1 .brookfield-section h1 {
  font-weight: 800;
  font-size: 53px;
  color: #fff;
  margin-top: 100px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.safewaterBG1 .brookfield-section button {
  color: #0f3557;
  font-weight: 600;
  font-size: 21px;
  background-color: #fff;
  padding: 14px 34px;
  border-radius: 13px;
  border: 3px solid #3581f7;
}
.safewaterBG1 .CardSection {
  margin: 10px 10px 10px 0;
}
.safewaterBG1 .cardBgSec {
  min-height: 220px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  margin-bottom: 20px;
  margin: 10px;
}

.safewaterBG1 .cardBgSecOne {
  background-image: url("../assets/images/digital-ads/Card-img1.jpg");
}
.safewaterBG1 .cardBgSecTwo {
  background-image: url("../assets/images/digital-ads/Card-img2.jpg");
}
.safewaterBG1 .cardBgSecThree {
  background-image: url("../assets/images/digital-ads/Card-img3.jpg");
}
.safewaterBG1 .cardBgSecFour {
  background-image: url("../assets/images/digital-ads/Card-img4.jpg");
}
.safewaterBG1 .cardBgSecFive {
  background-image: url("../assets/images/digital-ads/Card-img5.jpg");
}

.safewaterBG1 h6.StayHeading.text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #052238;
  background-color: #fff;
  padding: 14px 34px;
  border-radius: 13px;
  border: 3px solid #3581f7;
  margin-top: 94px;
  margin-bottom: 21px;
}

.safewaterBG1 h6.StayHeading .Heading {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 21px;
  color: #052238;
}

.safewaterBG1 .cardBgSec {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  padding: 26px 15px;
  line-height: 26px;
  text-transform: uppercase;
  white-space: initial;
}
.safewaterBG1 .CardRow {
  width: 100%;
}
.safewaterBG1 .publicList p {
  text-shadow: 0px 1px 10px #000;
}

.safewaterBG1 div#nav-collapse {
  display: block !important;
}

.safewaterBG1 .brookfieldLogoImage {
  width: 200px;
}
.safewaterBG1 .navbar-nav {
  flex-direction: row;
  justify-content: space-between;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: unset !important;
}
.safewaterBG1 .CardRow .publicModalBtn {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: default !important;
}
.safewaterBG1 .SafetySection .CardRow .publicModalBtn:hover,
:active,
:focus {
  border: 0;
  box-shadow: none !important;
}

/* Media */
@media (min-width: 393px) and (max-width: 420px) {
  .safewaterBG1 .cardBgSec {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (min-width: 300px) and (max-width: 392px) {
  .safewaterBG1 .cardBgSec {
    background-position: center;
    min-height: 230px !important;
  }
  .safewaterBG1 .publicList {
    margin: 0;
    flex: 0 0 1%;
  }
}
@media (max-width: 769px) {
  .slick-slide {
    padding: 10px;
  }
  .slick-next {
    right: -5px;
  }
  .slick-prev {
    left: -5px;
    z-index: 15;
  }
}
@media (min-width: 320px) and (max-width: 393px) {
  .safewaterBG1 .cardBgSec {
    min-height: 300px;
  }
}
@media (max-width: 768px) {
  .stateSection .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .safewaterBG1 {
    background-position: center;
  }
  .safewaterBG1 .cardBgSec {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 330px;
  }
  .safewaterBG1 .brookfield-section h1 {
    font-weight: 800;
    font-size: 30px;
    margin-top: 50px;
  }
  .safewaterBG1 .brookfield-section button {
    font-size: 16px;
    padding: 10px;
  }
  .safewaterBG1 .SafetySection {
    padding-bottom: 70px;
  }
  .safewaterBG1 .CardSection {
    max-width: 100% !important;
    flex: auto !important;
    margin-bottom: 20px;
    width: 100% !important;
    margin-left: 0;
  }
  .safewaterBG1 div#nav-collapse {
    display: block !important;
  }
  .safewaterBG1 .publicList {
    margin: 0;
  }

  .safewaterBG1 .navbar-nav {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .safewaterBG1 .brookfieldLogoImage {
    width: 100px !important;
  }
  /* Background Images Setting */
  .BgDivTwo {
    background-position: top;
    background-size: cover;
    min-height: 100vh;
    background-position: top;
  }
  .BgDivThree {
    background-position: -30px center;
    background-size: cover;
  }
  .BgDivFive {
    background-position: -170px center;
  }
  .BgDiv {
    background-attachment: fixed;
  }
  .BgDivTwo .PublicTable {
    margin-top: 20rem !important;
  }
  .BgDiv .HeadingSection {
    margin: 0 20px;
    padding-top: 2rem;
  }
  .BgDiv .HeadingSection .GreenHeading {
    font-size: 36px;
    line-height: unset;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 36px;
    line-height: unset;
  }
  .BgDiv .PublicTable {
    margin-top: 20rem;
    padding: 10px;
  }
  .BgDivOne .PublicTable tr {
    font-size: 20px;
  }
  .PublicTable tr {
    font-size: 20px;
  }
  .PublicTable td {
    font-size: 20px;
  }
  .PublicTable .HeadTr p {
    width: calc(100% + 45%);
    padding: 10px 15px;
  }
  .PublicTable #table1 .LinkDiv p {
    font-size: 21px;
    margin-top: 15px;
  }
  .PublicTable .ImgTd img {
    padding: 0;
  }
  .safewaterBG1 h6.StayHeading.text {
    margin-top: 50px;
    margin-bottom: 0px;
  }
}
@media (min-width: 478px) and (max-width: 768px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 50% !important;
    max-width: 100%;
  }
}

@media (min-width: 585px) and (max-width: 769px) {
  .safewaterBG1 .cardBgSec {
    min-height: 210px;
  }
  .safewaterBG1 .cardBgSec {
    line-height: 22px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 33%;
    max-width: 100%;
  }
  .safewaterBG1 {
    /* background-image: url(/img/BGimg1.e7706cd1.png); */
    background-repeat: no-repeat;
    min-height: 100vh;
    background-position: top;
    background-attachment: fixed;
  }
  .safewaterBG1 .brookfield-section h1 {
    font-size: 40px;
    margin-top: 65px;
  }
  .safewaterBG1 div#nav-collapse {
    display: block !important;
  }
  .safewaterBG1 .publicList {
    margin: 0;
  }
  .safewaterBG1 .navbar-nav {
    flex-direction: row;
    justify-content: space-between;
  }
  /* Background Images Setting */
  .BgDivOne {
    background-position: 135px center;
  }
  .BgDivTwo {
    background-position: -95px center;
  }
  .BgDivThree {
    background-position: 110px center;
  }
  .BgDivFour {
    background-position: -190px center;
  }
  .BgDivFive {
    background-position: -165px center;
  }

  .BgDiv .PublicTable {
    margin-top: 30rem !important;
  }

  .BgDiv {
    background-attachment: fixed;
  }
  .BgDiv .HeadingSection {
    margin: 0 65px;
    padding-top: 2rem;
  }
  .BgDiv .HeadingSection .GreenHeading {
    font-size: 45px;
    line-height: unset;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 45px;
    line-height: unset;
  }
  .BgDiv .PublicTable {
    margin-top: 25rem;
    padding: 10px;
  }
  .BgDivOne .PublicTable tr {
    font-size: 24px;
  }
  .PublicTable tr {
    font-size: 24px;
  }
  .PublicTable td {
    font-size: 24px;
  }
  .PublicTable .HeadTr p {
    width: calc(100% + 45%);
    padding: 10px 15px;
  }
  .PublicTable #table1 .LinkDiv p {
    font-size: 21px;
    margin-top: 15px;
  }
  .PublicTable .ImgTd img {
    padding: 0;
  }
}
@media (min-width: 910px) and (max-width: 920px) {
  /* .modal-dialog {
    max-width: 600px !important;
  } */
}
@media (min-width: 1400px) {
  .safewaterBG1 .SafetySection {
    padding-bottom: 150px;
  }
  .BgDivThree {
    background-position: inherit;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 33%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 25%;
    max-width: 100%;
  }
}
@media (min-width: 1300px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 20%;
    max-width: 100%;
  }
}
.locationIcon {
  margin-bottom: 10px;
  margin-right: 10px;
}
.card-body.card-img-overlay {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative !important;
  flex-direction: row-reverse;
  padding: 1.25rem 10px;
}
.card {
  background-color: transparent !important;
}
.card-title {
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  line-height: 26px;
  text-transform: uppercase;
  white-space: initial;
}
@media (min-width: 992px) and (max-width: 1025px) {
  .safewaterBG1 {
    /* background-image: url(/img/BGimg1.e7706cd1.png); */
    background-position: top;
    /* background-size: cover; */
    background-attachment: fixed;
    min-height: 100vh;
  }
  .BgDiv {
    background-attachment: fixed;
  }
  .BgDivThree {
    background-size: cover;
  }

  .BgDiv .HeadingSection .GreenHeading {
    font-size: 80px;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 80px;
  }
  .BgDiv .HeadingSection .GreenHeading {
    font-size: 90px;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 90px;
  }
}
@media (min-width: 768px) {
  .BgDivFour .PublicTable {
    margin-top: 35rem;
  }
  .BgDivThree .HeadingSection {
    padding-top: 8rem;
  }
  .safewaterBG .bg-info p {
    font-size: 16px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .safewaterBG1 {
    /* background-image: url(/img/BGimg1.e7706cd1.png); */
    background-position: center;
    min-height: 100vh;
  }
  .safewaterBG1 .cardBgSec {
    background-position: center;
  }
  .BgDivThree {
    background-position: -25px center;
  }
  .BgDiv .HeadingSection .GreenHeading {
    font-size: 90px;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 90px;
  }
  .BgDivOne .HeadingSection .GreenHeading,
  .BgDivFive .HeadingSection .GreenHeading {
    font-size: 77px;
  }
  .BgDivOne .HeadingSection .WhiteHeading,
  .BgDivFive .HeadingSection .WhiteHeading {
    font-size: 77px;
  }
}

.safewaterBG1 .bg-info {
  background-color: transparent !important;
}

.safewaterBG1 .bg-info p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  color: #fff;
}
</style>
