<template>
  <main>
    <section class="topsection">
      <div class="row m-0">
        <div
          class="col-md-4 col-lg-3 pt-5"
          style="background: rgb(15 53 87 / 3%)"
        >
          <ul class="listtext mt-5">
            <li>
              <router-link to="/terms" class="active">Terms of Use</router-link>
            </li>
            <li>
              <router-link to="/terms-data-protection"
                >Data Protection & Privacy</router-link
              >
            </li>
            <li @click="redirectToDataScreen()">
              <router-link to="">About Our Ads</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-8 col-lg-9 pt-5">
          <div class="contentt pb-5 mt-5">
            <p><u>Introduction</u></p>
            <p><u> </u></p>
            <p>
              Please read these BrookfieldRenewableUS.com Website Terms of Use
              (“<strong>Terms of Use</strong>”) carefully before using the
              <a href="http://www.BrookfieldRenewableUS.com" target="_blank"
                >www.BrookfieldRenewableUS.com</a
              >
              website (the “<strong>Website</strong>”). By browsing, accessing
              or otherwise using the Website, you agree to accept these Terms of
              Use.
            </p>
            <p>
              Your visit to the Website, including, but not limited to, all
              images, text, illustrations, designs, icons, photographs,
              programs, music clips, downloads, systems and methods of trading,
              video clips, graphics, user interfaces, visual interfaces,
              information, data, tools, products, written materials, services,
              design, structure, selection, coordination, expression content,
              information, design, marks and layout therein,  including but not
              limited to the design, structure, selection, coordination,
              expression and arrangement of any of the foregoing available on or
              through the Website, together (the “<strong>Content</strong>”), is
              subject to these Terms of Use, our Privacy Policy and our Cookies
              Policy, which may be updated by us at any time without notice to
              you. If we update our Terms of Use, we will post the updated Terms
              of Use on the Website. Any such changes will be effective upon
              posting. If you do not agree with these Terms of Use, you should
              not use the Website and you agree that your sole and exclusive
              remedy is to discontinue using the Website. The Website is not
              designed for use by minors and minors should not provide personal
              information through this Website.
            </p>
            <p>
              Nothing contained in these Terms of Use is intended to modify any
              other written agreement you may have with Brookfield (if any) that
              may be in effect. Additional terms and conditions of use may be
              applicable to password-restricted areas of the Website.
            </p>
            <p>
              For the purposes of these Terms of Use,
              “<strong>Brookfield</strong>,” “<strong>we</strong>” and
              “<strong>us</strong>” means the Brookfield entity which controls
              this Website and the “<strong>User</strong>” means any person
              accessing the Website. The websites of other Brookfield affiliates
              are governed by separate terms of use.
            </p>
            <p>
              <strong
                >Any disputes between you and Brookfield in connection with
                these Terms of Use may be resolved in arbitration or small
                claims court, subject to applicable law.</strong
              >
            </p>
            <p>
              If you have any questions or comments about these Terms of Use,
              please contact us at
              <span
                ><a href="mailto:Privacy@Brookfieldrenewable.com"
                  >Privacy@Brookfieldrenewable.com</a
                ></span
              >
            </p>
            <p>
              <u>Use of the Website and Content </u><u><br /> </u
              ><strong
                >You are responsible for your use of the Website and the
                Content. You undertake to use your best endeavors to ensure that
                nothing you do whilst accessing the Website will damage the
                Website or otherwise violate these Terms of Use.</strong
              >
            </p>
            <p>
              The Website, including, the Content, is the property of
              Brookfield, Brookfield affiliates, or Brookfield service providers
              and is subject to copyright, trade mark and other laws. To the
              extent expressly permitted herein, Brookfield grants you a
              limited, revocable, non-exclusive and non-transferable personal
              right to view, download and print pages from the Website solely
              for your personal and non-commercial use. This right will
              terminate immediately upon your unauthorized use of the Website or
              Content. Subject to the foregoing, no intellectual property rights
              in respect of the Website or Content are granted or extended as a
              result of Brookfield making the Website available. All rights not
              expressly granted to you in these Terms of Use are reserved.
            </p>
            <p>You may not (and may not encourage or assist others) to:</p>
            <p style="padding-left: 40px">
              (i) reproduce, republish, distribute, exploit commercially, use or
              modify without Brookfield’s express written consent, the Website
              or Content;
            </p>
            <p style="padding-left: 40px">
              (ii) access, copy or download the Website or Content using
              automated methods, including but not limited to, “web scraping” or
              “bots”, “robots” or “spiders” that “harvest” the Website or
              Content, interfere with the functioning of the Website or Content
              or restrict or inhibit any others from using the Website or
              Content;
            </p>
            <p style="padding-left: 40px">
              (iii) use any trade mark, service mark or logo of Brookfield or
              any third party that appears on the Website without prior written
              consent or remove or obscure any copyright or other notices
              contained in the Website or the Content;
            </p>
            <p style="padding-left: 40px">
              <em></em>(iv) if you link other websites to the Website, imply or
              suggest that Brookfield has endorsed or is affiliated with such
              websites and you may not display the Website as “framed” or
              “mirrored” within another website;
            </p>
            <p style="padding-left: 40px">
              (v) delete, decompose, decompile, reverse engineer, disassemble or
              otherwise deconstruct all or any portion of the Website or Content
              or any of the Websites’ source code or software;
            </p>
            <p style="padding-left: 40px">
              (vi) transmit any virus, worm, time bomb or similar system
              interference through the Website;
            </p>
            <p style="padding-left: 40px">
              (vii) violate these Terms of Use, the Privacy Policy or Cookies
              Policy or any law, regulation, rule or intellectual property or
              contractual rights of others, or attempt to violate the security
              of the Website, including attempting to gain unauthorized access
              to Content or use or gain access to the identities, information or
              computers of others through the Website;
            </p>
            <p style="padding-left: 40px">
              (viii) impersonate any person or entity or create a user account
              for anyone other than yourself or create another user account
              without permission if Brookfield has suspended or terminated your
              user account; or
            </p>
            <p style="padding-left: 40px">
              (ix) use any hidden text or metatags using “Brookfield” on a
              website or content published on a website or otherwise.
            </p>
            <p>
              Brookfield has the right (but not the obligation) to monitor the
              Website for any unauthorized or objectionable conduct and to take
              all appropriate actions in response, without notice to you, and we
              may at any time revoke your right to use all or any portion of the
              Website or Content. Brookfield may investigate any complaint
              regarding a suspected violation of these Terms of Use, and may
              report any activity relating to the Website or Content to
              regulators, law enforcement officials or other persons or entities
              that it deems appropriate.
            </p>
            <p>
              <strong
                >The Website or any part of the Website may be periodically
                unavailable, and Content may be removed or modified at any time
                without notice. Brookfield may, in its sole discretion,
                restrict, suspend or cancel access to or use of the Website, for
                all or any one or number of Users. Users will have no right to
                compensation or other remedy for being unable to access the
                Website.</strong
              >
            </p>
            <p><u>Restricted Access</u></p>
            <p>
              The Website is operated and controlled by us in the United States.
              Access to the Website from countries or territories where such
              access is illegal is prohibited. Brookfield makes no
              representations that the transactions, financial or investment
              products, securities, and other services ("<strong
                >Products or Services</strong
              >") discussed on or accessible through the Website are available
              or appropriate for sale or use in all jurisdictions or by all
              Users. Those who access the Website do so on their own initiative
              and are responsible for compliance with local laws, rules and
              regulations.
            </p>
            <p>
              <strong
                ><u
                  >Website Disclaimers and Limitations of Liability
                </u></strong
              >
            </p>
            <p>
              <strong
                >Subject to any applicable law that cannot be excluded, the
                Website and Content are provided “as is” and “as available”
                without any representation, warranty or condition. No reliance
                should be placed on any information on the Website or the
                Content. Users agree to bear all risks associated with their use
                of the Website and Content, including any reliance on the
                availability, accuracy, completeness, timeliness, usefulness or
                appropriateness of the Website or Content. You agree that
                Brookfield is not liable for any action you take, omission, or
                decision you make in reliance on the Website or the Content,
                subject to applicable law.
              </strong>
            </p>
            <p>
              <strong
                >The Content and the Website are for informational purposes only
                and are not investment, professional, legal or other advice nor
                are they to be relied upon in making an investment or other
                decision. The Website and Content is not an offer to sell, or a
                solicitation of an offer to buy and do not constitute an
                invitation or inducement of any sort to any person.
              </strong>
            </p>
            <p>
              <strong
                >Certain information contained on the Website and in the Content
                may constitute “forward-looking statements” as defined in
                applicable securities laws.  Forward-looking statements include
                statements that are predictive in nature, depend upon or refer
                to future events or conditions, and include statements regarding
                Brookfield’s competitive strengths, goals, expansion, growth,
                future success, operations, business, financial condition,
                expected financial results, performance, prospects,
                opportunities, priorities, targets, goals, ongoing objectives,
                strategies, and outlook. Words such as “anticipate”, “estimate”,
                “expect”, “project”, “intend”, “plan” and “believe” are intended
                to identify forward-looking statements. No reliance should be
                placed on forward-looking statements because they involve known
                and unknown risks, uncertainties, and other factors which may
                cause the actual results, performance, or achievements of
                Brookfield to differ materially. Except as required by law,
                Brookfield undertakes no obligation to update or revise
                statements or information on the Website or in Content.
              </strong>
            </p>
            <p>
              <strong
                >Any transactions described on the Website or in any Content as
                having been engaged in by Brookfield are included as
                representative transactions for illustrative purposes only. Past
                performance is not indicative of future results. No assurance or
                representation is made, that comparable results or any target
                returns disclosed on the Website or in Content will be
                achieved. 
              </strong>
            </p>
            <p>
              <strong
                >You agree that, to the maximum extent permitted by applicable
                law, in no event shall you seek to hold Brookfield, its
                affiliates and/or their respective officers, directors,
                managers, partners, members, shareholders, employees and agents
                (“Affiliates”), liable and in no event shall Brookfield or its
                Affiliates be liable to any party, whether in contract, tort
                (including negligence) or otherwise, for damages (including
                direct, indirect, incidental, consequential, special, punitive,
                exemplary and aggravated damages and damages for loss of use,
                profits or revenue) or any other remedy relating to the access,
                use, inability to use, availability of the Website or the
                Content, irrespective of whether Brookfield has been advised of
                the possibility of such damages and/or losses.</strong
              >
            </p>
            <p>
              <strong
                >The liability of Brookfield and/or its Affiliates for breach of
                any condition, guarantee or warranty implied by statute that
                cannot be excluded, is limited to the maximum extent permitted
                by law, at Brookfield’s option, to the re-supply of goods or
                services, or the payment of the costs of having goods or
                services re-supplied.
              </strong>
            </p>
            <p><u>Indemnification </u></p>
            <p>
              You agree that a condition of your use of the Website and Content
              is that you indemnify Brookfield, its Affiliates and their
              respective representatives harmless against any and all
              liabilities, losses, damages, suits, claims, demands, costs
              (including court costs and legal fees), fines and actions of any
              kind or nature suffered as a result of your use of the Website or
              Content, your breach, violation or non-performance of any term or
              condition of these Terms of Use, or your violation of any third
              party’s rights relating to the Website or Content.
            </p>
            <p><u>Linked Websites </u></p>
            <p>
              The Website may contain links to other websites. We are not
              responsible for the content, security or privacy practices of
              other websites and we do not endorse other websites or their
              content by virtue of linking to them from the Website. You should
              review the terms of use and privacy policies of any third party
              website that you visit.
            </p>
            <p><u>Password Protected Links</u></p>
            <p>
              Some pages on the Website are available only to clients with
              registered accounts and log-on credentials. You are responsible
              for the confidentiality of your log-on credentials and account
              information, and for all actions taken on the Website while logged
              on with your credentials. You should not disclose your log-on
              credentials or account information and you should choose a unique
              password. Brookfield reserves the right to suspend or cancel
              Website accounts and log-on credentials at any time, for any, or
              no, reason and without notice. If you suspect that your log-on
              credentials have been compromised, please notify us immediately
              using the support contact information provided on the relevant
              account log-on page.
            </p>
            <p>
              Pages on the Website that are accessible only via registered
              accounts and with log-on credentials are only available to
              institutional or professional Users. By accessing such pages,
              Users will be deemed to have confirmed that they meet the criteria
              for this classification. Such confirmation will be treated by
              Brookfield as the User's acceptance that they understand the
              classification of these Terms of Use as applicable under the
              regulatory regime of their country of residence.
            </p>
            <p><u>User Content</u></p>
            <p>
              Electronic communications can be intercepted by third parties and
              electronic mail and other transmissions to and from the Website
              and Brookfield may not be secure. Any communications to Brookfield
              should be sent to the contact information provided in the “Contact
              Us” section of the Website. You are responsible for any content
              that you post on the Website or submit to us via contact forms on
              the Website, email, social media accounts or otherwise, including
              your feedback, comments, questions, ideas, suggestions, survey
              responses or other information or materials (“<strong
                >User Content</strong
              >”). You hereby represent and warrant that any User Content you
              submit to us is either your original content or that you have
              obtained all necessary rights to your User Content, including the
              right to authorize us to use your User Content as set forth in
              these Terms of Use. By submitting any User Content, you hereby
              grant to Brookfield an irrevocable, perpetual, worldwide,
              non-exclusive, transferable, royalty-free, fully paid-up,
              sub-licensable license to use, display, reproduce, publish,
              transmit, store, modify and create derivative works of your User
              Content.
            </p>
            <p><u>Material Interests</u></p>
            <p>
              Any part of Brookfield and/or its Affiliates may have or have had
              an interest or holding, relationship or other arrangement with the
              Products or Services, which may be material. Potential investors
              should refer to the applicable offering documents for any Product
              or Service and in particular, the potential conflicts of interest
              discussed therein.
            </p>
            <p><u>Notice to California Residents </u></p>
            <p>
              Under California Civil Code Section 1789.3, you may contact the
              Complaint Assistance Unit of the Division of Consumer Services of
              the California Department of Consumer Affairs in writing at 1625
              N. Market Blvd., Suite S-202, Sacramento, California 95834, or by
              telephone at (800) 952-5210 in order to resolve a complaint
              regarding the service or to receive further information regarding
              use of the service.
            </p>
            <p><u>Dispute Resolution by Binding Arbitration</u></p>
            <p>
              Subject to applicable law, in the interest of resolving disputes
              between you and Brookfield, you agree that Brookfield may, at its
              sole discretion, require that a dispute arising out of or in
              connection with these Terms of Use or your use of the Website or
              Content shall be resolved through binding arbitration or small
              claims court, at Brookfield’s option, instead of in courts of
              general jurisdiction. Arbitration is more informal than a lawsuit
              in court. Arbitration uses a neutral arbitrator instead of a judge
              or jury, allows for more limited discovery than in court, and is
              subject to very limited review by courts.  Arbitrators can award
              the same damages and relief that a court can award. Any
              arbitration under these Terms of Use will take place on an
              individual basis; class arbitrations and class actions are not
              permitted.  You acknowledge and agree that the arbitrator may
              award relief (including monetary, injunctive, and declaratory
              relief) only in favor of the individual party seeking relief and
              only to the extent necessary to provide relief necessitated by
              that individual party’s claim(s).  Any relief awarded cannot
              affect other Users.
            </p>
            <p><u>Governing Law</u></p>
            <p>
              Subject to the Disputes Resolution by Binding Arbitration section
              above, these Terms of Use and the rights and obligations of any
              recipient or viewer of the Website or the Content (and any person
              that they represent) arising out of or in connection with the
              Website or the Content, whether contractual, non-contractual,
              pre-contractual or otherwise, are governed by the laws of the
              State of New York and any disputes arising out of or in connection
              with the foregoing shall be subject to the exclusive jurisdiction
              of the state or federal courts located in New York City. You agree
              to waive trial by jury in any such action.
            </p>
            <p>
              No waiver by Brookfield or any of its Affiliates of any right
              under or term or provision of these Terms of Use will be deemed a
              waiver of any other right, term or provision of these Terms of
              Use. If any provision of these Terms of Use shall be deemed
              unlawful, void or for any reason unenforceable, then that
              provision shall be deemed severable from these Terms of Use and
              shall not affect the validity and enforceability of any remaining
              provisions.
            </p>
            <p>
              You should be aware that any rules and/or regulations applicable
              to the provision of financial services (and the resultant investor
              protection benefits that may be available), may not be applicable
              to interfacing conducted through the internet and its various
              applications, of which this material forms part.
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  created() {
    document.title = "Terms of Use | SafeWaters by Brookfield Renewable US";
  },
  methods: {
    redirectToDataScreen() {
      this.$router.replace({
        path: "terms-data-protection",
        query: { aboutOurAds: 1 },
      });
    },
  },
};
</script>

<style type="text/css">
.listtext {
  list-style-type: none;
}
.listtext li {
  margin-bottom: 10px;
}
.listtext li a {
  line-height: 125.18%;
  color: #0f3557;
  font-weight: 700;
}
.listtext li a:hover,
.listtext li a.active {
  color: #509e2f;
}
</style>
