<template>
  <main>
    <section class="topsection">
      <div class="listviewbox">
        <div class="row m-0">
          <div class="col-md-5 p-0">
            <div class="addfav"></div>
            <hr />
            <div class="singlefacleft" v-if="facilityInfo">
              <div
                class="facleft"
                v-bind:class="
                  facilityInfo.alertCount > 0
                    ? 'orange-alert'
                    : facilityInfo.notificationCount > 0
                    ? 'green-notification'
                    : ''
                "
              >
                <img src="../assets/images/water-drops.png" alt="Facilities" />
              </div>
              <div class="facright">
                <h1>{{ facilityInfo.name }}</h1>
                <p>
                  <i class="fa fa-map-marker"></i>
                  {{
                    facilityInfo.nearTown
                      ? "Near " + facilityInfo.nearTown
                      : "NA"
                  }}
                </p>
              </div>
            </div>
            <div class="singlefacleftdetails">
              <div class="faceupdate">
                <i class="fa fa-refresh"></i> Updated:
                <span>{{ updateDate | formatDate1 }}</span>
              </div>
              <ul class="singlefacedata nocursor">
                <li
                  v-for="(matrices, index) of facilityInfo.tb_facilityMatrices"
                  v-bind:key="index"
                >
                  <h1>
                    {{ matrices.value || "0" }}
                    <small
                      >{{ matrices.unit }} as of
                      {{ matrices.time | formatDate }}</small
                    >
                  </h1>
                  <p>{{ matrices.description }}</p>
                </li>
              </ul>
              <hr />
              <div
                class="facecontactinfo"
                v-if="
                  facilityInfo &&
                  (facilityInfo.phone ||
                    (facilityInfo.email && facilityInfo.email != ''))
                "
              >
                <h1>FACILITY CONTACT</h1>
                <a
                  :href="'tel:' + facilityInfo.phone"
                  class="tel"
                  v-if="facilityInfo.phone"
                  >{{ facilityInfo.phone }}</a
                >
                <a
                  :href="'mailto:' + facilityInfo.email"
                  v-if="facilityInfo.email && facilityInfo.email != ''"
                  >{{ facilityInfo.email }}</a
                >
                <hr />
              </div>
              <div
                class="facecontactinfo"
                v-if="
                  facilityInfo &&
                  facilityInfo.tb_facilitylinks &&
                  facilityInfo.tb_facilitylinks.length > 0
                "
              >
                <h1>MORE INFORMATION</h1>
                <div
                  v-for="facilityLink of facilityInfo.tb_facilitylinks"
                  v-bind:key="facilityLink.id"
                >
                  <h2
                    v-on:click="openSelectedLink(facilityLink)"
                    style="cursor: pointer"
                  >
                    {{ facilityLink.title }}
                  </h2>
                  <p>{{ facilityLink.desc }}</p>
                </div>
                <hr />
              </div>

              <div class="singlesafeinfo" v-if="facilityInfo">
                <div
                  v-html="facilityInfo.message"
                  v-if="facilityInfo.message"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-md-7 p-0">
            <div class="singlefacright">
              <div class="addfav"></div>
              <hr />
              <div class="singlefacrightcontent">
                <div class="noticationlist">
                  <div
                    class="noticationmain"
                    v-for="item of facilityAlert"
                    v-bind:key="item.id"
                    v-bind:class="
                      item.type === 'Alert' ? 'orange-border' : 'green-border'
                    "
                  >
                    <div
                      class="noticationhead"
                      v-bind:class="
                        item.type === 'Alert'
                          ? 'orange-background'
                          : 'green-background'
                      "
                    >
                      <i
                        class="fa fa-exclamation-triangle"
                        v-if="item.type === 'Alert'"
                      ></i>
                      <i
                        class="fa fa-bell"
                        v-if="item.type === 'Notifications'"
                      ></i>
                      <div>
                        <h1>{{ item.title }}</h1>
                        <p>{{ item.updateOn | formatDate1 }}</p>
                      </div>
                    </div>
                    <div class="noticationtext">
                      <p v-html="item.message"></p>
                    </div>
                  </div>
                </div>

                <div style="margin-bottom: 50px">
                  <h1 v-if="shortSchedule.length > 0">Short-term Schedule</h1>
                  <table v-if="shortSchedule.length > 0" class="tbSchedule">
                    <thead>
                      <tr>
                        <th>START</th>
                        <th>END</th>
                        <th>Generators</th>
                        <th>CFS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item of shortSchedule" v-bind:key="item.id">
                        <td>{{ item.start | formatDate }}</td>
                        <td>{{ item.end | formatDate }}</td>
                        <td>
                          {{ item.generators > 0 ? item.generators : "N/A" }}
                        </td>
                        <td>{{ item.flow }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="mt-4"
                    v-html="facilityInfo.shortTermMessage"
                    v-if="facilityInfo.shortTermMessage"
                  ></div>
                </div>
                <transition name="fade">
                  <div>
                    <h1 v-if="longSchedule.length > 0">Long-term Schedule</h1>
                    <table v-if="longSchedule.length > 0" class="tbSchedule">
                      <thead>
                        <tr>
                          <th>START</th>
                          <th>END</th>
                          <th>Generators</th>
                          <th>CFS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item of longSchedule" v-bind:key="item.id">
                          <td>{{ item.start | formatDate }}</td>
                          <td>{{ item.end | formatDate }}</td>
                          <td>{{ item.generators }}</td>
                          <td>{{ item.flow }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      class="mt-4"
                      v-html="facilityInfo.longTermMessge"
                      v-if="facilityInfo.longTermMessge"
                    ></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="safeinfo">
      <div class="aboutsafeinfo">
        <HomepageNote />
      </div>
    </section>
  </main>
</template>
<script>
import Vue from "vue";

import HomepageNote from "../components/homepageNote";
import moment from "moment";
import customInstance from "../config";

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(value).format("MM/DD/YYYY hh:mm A");
  }
});

Vue.filter("formatDate1", function (value) {
  if (value) {
    return moment(Number(value)).format("ddd, MMMM DD, hh:mm a");
  }
});

export default {
  components: {
    HomepageNote,
  },
  data() {
    return {
      facilityInfo: {},
      shortSchedule: [],
      longSchedule: [],
      facilityAlert: [],
      previewId: this.$route.params.previewId,
      newPreviewInfo: {},
      updateDate: "",
    };
  },

  created() {
    this.getPreview();
  },
  methods: {
    getPreview() {
      customInstance
        .get("/dashboard/preview/" + this.previewId)
        .then((res) => {
          if (res.data.status == 200) {
            this.newPreviewInfo = res.data.data.preview;
            this.getFacilityInfo(res.data.data.slug);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deletePreview() {
      customInstance
        .delete("/dashboard/preview/" + this.previewId)
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilityInfo(facid) {
      customInstance
        .get("/facility/" + facid)
        .then((res) => {
          if (res.data.status == 200) {
            this.facilityInfo = res.data.data[0];
            document.title =
              this.facilityInfo.name +
              ", " +
              this.facilityInfo.region +
              " - Water Release Information | SafeWaters by Brookfield Renewable US";
            this.updateDate = "";

            this.getFacilitySchedule(this.facilityInfo.id);
            this.getFacilityAlert(this.facilityInfo.id);

            if (this.facilityInfo.tb_facilityMatrices.length > 1) {
              this.updateDate = this.facilityInfo.tb_facilityMatrices.reduce(
                function (a, b) {
                  return new Date(a.updateOn) > new Date(b.updateOn)
                    ? a.updateOn
                    : b.updateOn;
                }
              );
            }

            if (this.facilityInfo.tb_facilityMatrices.length == 1) {
              this.updateDate =
                this.facilityInfo.tb_facilityMatrices[0].updateOn;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilityAlert(facid) {
      customInstance
        .get("public/alert/" + facid)
        .then((res) => {
          if (res.data.status == 200) {
            this.facilityAlert = res.data.data;
            if (this.newPreviewInfo.id) {
              let index = this.facilityAlert.findIndex((val) => {
                return val.id === this.newPreviewInfo.id;
              });
              this.facilityAlert[index] = this.newPreviewInfo;
            } else {
              this.facilityAlert.push(this.newPreviewInfo);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilitySchedule(facid) {
      customInstance
        .get("/schedule/" + facid)
        .then((res) => {
          if (res.data.status == 200) {
            this.shortSchedule = res.data.data.filter((val) => {
              return val.type === "Short";
            });

            this.longSchedule = res.data.data.filter((val) => {
              return val.type === "Long";
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openSelectedLink(link) {
      if (link.link.startsWith("http://") || link.link.startsWith("https://")) {
        window.open(link.link, "_blank");
      } else {
        window.open("//" + link.link, "_blank");
      }
    },
    openSelectedAttachment(link) {
      window.open(link, "_blank");
    },
    splitByLastDot(text) {
      var index = text.lastIndexOf(".");
      return [text.slice(0, index), text.slice(index + 1)][1];
    },
  },
  destroyed() {
    this.deletePreview();
  },
};
</script>
