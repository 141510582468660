import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {};
Vue.use(Toast, options);

export default class ToasterService {

    success(message) {
        Vue.$toast.success(message, {
            timeout: 3000,
            hideProgressBar: true,
        });
    }

    error(message) {
        Vue.$toast.error(message, {
            timeout: 3000,
            hideProgressBar: true,
        });
    }

}