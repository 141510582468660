<template>
  <div>
    <div class="row notesafe">
      <div class="col-md-2 col-lg-2 pt-2 mb-3">
        <img src="../assets/images/wearit.png" alt="wearit" width="150" />
      </div>
      <div class="col-md-10 col-lg-10">
        <div class="notesafeinner">
          <p>
            <b>Please note! </b>Water release schedules often change without
            notice due to unanticipated changes in weather conditions and power
            system requirements. Use caution near dams. A large amount of water
            may be discharged without warning at any time. Your safety depends
            on obeying all posted safety regulations and warnings.
          </p>
        </div>
      </div>
    </div>
    <div class="aboutsafetext">
      <p>
        <b>Disclaimer:</b> Water release schedules and flow levels posted by
        Brookfield Renewable as directed by the Federal Energy Regulatory
        Commission are for informational purposes only and are subject to change
        without notice. Brookfield Renewable makes no representation or
        warranty, expressed or implied concerning the accuracy, completeness and
        reliability or suitability of the data and/or information contained
        herein for any particular purpose. While Brookfield Renewable strives to
        make reasonable efforts to ensure that the data and/or information
        contained on the website is complete, accurate, reliable and suitable,
        errors may occur or information may be out dated. Brookfield Renewable
        at any time may revise or change the data and/or information posted
        without any obligation to notify past, current or prospective users.
      </p>
      <p>
        In no event will Brookfield Renewable, its affiliates, subsidiaries,
        officers, directors, shareholders, employees or agents be liable for any
        damages whatsoever in contract, tort or otherwise, including but not
        limited to, special, direct, indirect, general, consequential,
        incidental or punitive damages arising out of the use of the data and/or
        information posted. By accessing this website, the user acknowledges and
        agrees that any employment of the data and/or information posted is
        solely assumed and at the user’s own risk.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomepageNote",
};
</script>