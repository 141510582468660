export const getFacilitySearchData = (obj) => {
    const mergedArray = [];
    const encounteredSlugs = new Set();
    
    if (obj.facility.length > 0) {
        obj.facility.forEach(item => {
            if (!encounteredSlugs.has(item.slug)) {
                mergedArray.push({ ...item, type: 1 });
                encounteredSlugs.add(item.slug);
            }
        });
    }

    if (obj.riverSystem.length > 0) {
        obj.riverSystem.forEach(item => {
            if (!encounteredSlugs.has(item.slug)) {
                mergedArray.push({ ...item, type: 2 });
                encounteredSlugs.add(item.slug);
            }
        });
    }

    if (obj.town.length > 0) {
        obj.town.forEach(item => {
            if (!encounteredSlugs.has(item.slug)) {
                mergedArray.push({ ...item, type: 3 });
                encounteredSlugs.add(item.slug);
            }
        });
    }

    return mergedArray;
}
