<template>
  <main>
    <section class="topsection">
      <div class="row m-0">
        <div
          class="col-md-4 col-lg-3 pt-5"
          style="background: rgb(15 53 87 / 3%)"
        >
          <ul class="listtext mt-5">
            <li><router-link to="/terms">Terms of Use</router-link></li>
            <li>
              <router-link to="/terms-data-protection"
                >Data Protection & Privacy</router-link
              >
            </li>
            <li>
              <router-link to="/term-cookie-policy" class="active"
                >Cookie Policy</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-md-8 col-lg-9 pt-5">
          <div class="contentt pb-5 mt-5">
            <h3 class="mb-5">Cookie Policy</h3>
            <p>
              Cookies are used on this website. "Cookies" are small text files
              that store information about your interactions with a website,
              either temporarily or more permanently on the hard drive of your
              device. This website uses cookies to distinguish you from other
              users of this website, which helps us to provide you with a good
              experience when you browse this website and also allows us to
              improve this website. By continuing to browse this website, you
              are agreeing to our use of cookies.
            </p>

            <p>We use the following cookies:</p>

            <ul>
              <li>
                <strong>Strictly necessary cookies.</strong> These are cookies
                that are required for the operation of this website. They
                include, for example, cookies that enable you to log into secure
                areas of this website.
              </li>
              <li>
                <strong>Analytical/performance cookies.</strong> They allow us
                to recognise and count the number of visitors and to see how
                visitors move around this website when they are using it. This
                helps us to improve the way this website works for example, by
                ensuring that users are finding what they are looking for
                easily.
              </li>
            </ul>

            <p>
              Please note that third parties (including, for example, our
              affiliates, advertising networks and providers of external
              services like web traffic analysis services) may also use cookies,
              over which we have no control. These cookies are likely to be
              analytical/performance cookies.
            </p>

            <p>
              You can block cookies by activating the setting on your browser
              that allows you to refuse the setting of all or some cookies.
              However, if you use your browser settings to block all cookies
              (including essential cookies) you may not be able to access
              important functions or areas of this website.
            </p>

            <p>
              Users may allow, block or remove cookies installed on their
              computer or device by changing the configuration of the used
              browser. Example (for Internet Explorer): Go to Tools &gt;
              Internet Options &gt; Privacy.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  created() {
    document.title = "Cookie Policy | SafeWaters by Brookfield Renewable US";
  },
};
</script>

<style type="text/css">
.listtext {
  list-style-type: none;
}
.listtext li {
  margin-bottom: 10px;
}
.listtext li a {
  line-height: 125.18%;
  color: #0f3557;
  font-weight: 700;
}
.listtext li a:hover,
.listtext li a.active {
  color: #509e2f;
}
</style>
