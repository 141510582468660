<template>
  <div class="safewaterBG">
    <div class="SafetySection">
      <b-navbar toggleable="lg" type="dark" variant="info">
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav>
            <a target="_blank" href="https://brookfieldrenewableus.com/"
              ><img
                src="../assets/images/brookfieldus-logo.png"
                alt="brookfieldus"
                class="brookfieldLogoImage"
            /></a>
            <b-nav-form class="publicList">
              <P @click="flowInformationHandler()"
                >Safewaters Application
                <span class="greatIcon"
                  ><b-icon-arrow-right></b-icon-arrow-right></span
              ></P>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8 col-md-11 col-sm-12">
            <div class="brookfield-section">
              <h1>
                Brookfield Renewable, A <br />
                Leader Of Renewable Energy, <br />
                Reminds You To Stay Safe!
              </h1>
              <router-link to="/"
                ><button>
                  Explore water information in your area
                </button></router-link
              >
            </div>
          </div>
        </div>
        <h6 class="StayHeading">STAY SAFE</h6>
        <div class="row">
          <div class="CardRow">
            <VueSlickCarousel v-bind="sliderSetting">
              <div
                class="CardSection"
                v-for="(item, index) of staySafeCard"
                v-bind:key="index"
              >
                <b-button class="publicModalBtn">
                  <!-- @click="showModal(index)" -->
                  <div class="cardBgSec" v-bind:class="item.imageClass">
                    <h6>
                      {{ item.title }}
                    </h6>
                  </div>
                </b-button>
              </div>
            </VueSlickCarousel>
            <b-modal
              ref="staySafeModal"
              size="xl"
              class="PublicModalBody"
              hide-footer
              content-class="privacy-safety-modal-content"
            >
              <div
                class="BgDiv"
                v-bind:class="staySafeModalDetail.modalImageClass"
                v-if="staySafeModalDetail !== null"
              >
                <div class="HeadingSection">
                  <span
                    v-bind:class="
                      index % 2 === 0
                        ? titleHeadingOddColour()
                        : titleHeadingEvenColour()
                    "
                    v-for="(item, index) of staySafeModalDetail.modalTitle"
                    v-bind:key="index"
                  >
                    {{ item }}
                  </span>
                </div>

                <!-- Table -->
                <div class="PublicTable">
                  <table id="table1">
                    <tr class="HeadTr">
                      <p>
                        {{ staySafeModalDetail.tableHeadingOne }}
                      </p>
                    </tr>
                    <tr class="LinkDiv">
                      <td>
                        {{ staySafeModalDetail.tableHeadingTwo }}

                        <P
                          @click="flowInformationHandler()"
                          v-if="staySafeModalDetail.tableHeadingLink.length > 0"
                          style="cursor: pointer"
                        >
                          {{ staySafeModalDetail.tableHeadingLink }}</P
                        >
                      </td>
                      <td class="ImgTd">
                        <img
                          src="../assets/images/brookfieldus-logo.png"
                          alt="brookfieldus"
                          class="brookfieldLogoImage"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
        <h6 class="StayHeading">Find Your State for More Water Information</h6>
        <div class="row">
          <b-container-fluid class="bv-example-row mb-3 stateSection">
            <b-row cols="5">
              <b-col
                class="col-md-6 col-sm-12 col-xs-12"
                v-for="(item, index) in staySafeSubCard"
                :key="index"
              >
                <a :href="item.headingLink">
                  <b-card
                    :title="item.title"
                    img-alt="Card Image"
                    text-variant="white"
                    class="mb-2 cardBgSubSec"
                    v-bind:class="item.imageClass"
                    overlay
                  >
                    <img
                      :src="require('../assets/images/icon _locationpin_.png')"
                      class="locationIcon"
                    /> </b-card></a
              ></b-col>
            </b-row>
          </b-container-fluid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "PublicSafety",
  components: { VueSlickCarousel },
  data() {
    return {
      sliderSetting: {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        swipeToSlide: true,
        centerPadding: "20px",
        initialSlide: 0,
        arrows: true,
      },

      staySafeSubCard: [
        {
          title: "New York",
          headingLink: `${process.env.VUE_APP_BS_URL}map/new-york`,
          imageClass: "New-York",
        },
        {
          title: "Maine",
          headingLink: `${process.env.VUE_APP_BS_URL}map/maine`,
          imageClass: "Maine",
        },
        {
          title: "Pennsylvania",
          headingLink: `${process.env.VUE_APP_BS_URL}map/pennsylvania`,
          imageClass: "Pennsylvania",
        },
        {
          title: "Massachusetts",
          headingLink: `${process.env.VUE_APP_BS_URL}map/massachusetts`,
          imageClass: "Massachusetts",
        },
        {
          title: "West Virginia",
          headingLink: `${process.env.VUE_APP_BS_URL}map/west-virginia`,
          imageClass: "West-Virginia",
        },
        {
          title: "New Hampshire",
          headingLink: `${process.env.VUE_APP_BS_URL}map/new-hampshire`,
          imageClass: "New-Hampshire",
        },
        {
          title: "Maryland",
          headingLink: `${process.env.VUE_APP_BS_URL}map/maryland`,
          imageClass: "Maryland",
        },
        {
          title: "Tennessee",
          headingLink: `${process.env.VUE_APP_BS_URL}map/tennessee`,
          imageClass: "Tennessee",
        },
        {
          title: "North Carolina",
          headingLink: `${process.env.VUE_APP_BS_URL}map/north-carolina`,
          imageClass: "North-Carolina",
        },
      ],
      staySafeCard: [
        {
          title:
            "Cold water cools the body much faster than air. Swimming or immersion may cause hypothermia.",
          imageClass: "cardBgSecOne",
          modalTitle: ["KNOW", "BEFORE", "YOU", "GO"],
          modalImageClass: "BgDivOne",
          tableHeadingOne:
            "Plan your trip, check flows, and weather conditions. Share your plans with others.",
          tableHeadingTwo:
            "River conditions can change quickly and unexpectedly.",
          tableHeadingLink: "Visit safewaters.com to learn more.",
        },
        {
          title:
            "Water conditions near dams can change. Follow signage & stay at a safe distance.",
          imageClass: "cardBgSecTwo",
          modalTitle: ["BE", "AWARE &", "USE", "CAUTION"],
          modalImageClass: "BgDivTwo",
          tableHeadingOne:
            "Be aware of navigational hazards: submerged objects and debris.",
          tableHeadingTwo: "Have a safe, fun boating experience.",
          tableHeadingLink: "Visit safewaters.com for the latest conditions.",
        },
        {
          title:
            "Be aware of all hazards & remember many hazards may be unseen underwater.",
          imageClass: "cardBgSecThree",
          modalTitle: ["ADVENTURE", "AWAITS"],
          modalImageClass: "BgDivThree",
          tableHeadingOne:
            "Brookfield Renewable US Proudly Supports Outdoor Recreation.",
          tableHeadingTwo:
            "Support the outdoors: practice good stewardship, follow posted rules, and make memories.",
          tableHeadingLink: "",
        },
        {
          title:
            "Close to dams, water conditions may be different & can change rapidly.",
          imageClass: "cardBgSecFour",
          modalTitle: ["ADVENTURE", "AWAITS"],
          modalImageClass: "BgDivFour",
          tableHeadingOne:
            "Brookfield Renewable US Proudly Supports Outdoor Recreation.",
          tableHeadingTwo:
            "Support the outdoors: practice good stewardship, follow posted rules, and make memories.",
          tableHeadingLink: "",
        },
        {
          title:
            "Always wear a PFD when on or near waterways. Adhere to all signage & be vigilant.",
          imageClass: "cardBgSecFive",
          modalTitle: ["KNOW", "BEFORE", "YOU", "GO"],
          modalImageClass: "BgDivFive",
          tableHeadingOne:
            "Plan your trip, check flows, and weather conditions. Share your plans with others.",
          tableHeadingTwo:
            "River conditions can change quickly and unexpectedly.",
          tableHeadingLink: "Visit safewaters.com to learn more.",
        },
      ],
      staySafeModalDetail: null,
      modalIndex: 0,
    };
  },
  mounted() {
    if (window.innerWidth < 585) {
      this.sliderSetting.slidesToShow = 1;
    } else if (window.innerWidth >= 585 && window.innerWidth < 850) {
      this.sliderSetting.slidesToShow = 2;
    } else if (window.innerWidth >= 850 && window.innerWidth <= 1280) {
      this.sliderSetting.slidesToShow = 3;
    } else if (window.innerWidth > 1280 && window.innerWidth <= 1600) {
      this.sliderSetting.slidesToShow = 4;
    } else if (window.innerWidth > 1600) {
      this.sliderSetting.slidesToShow = 5;
    }

    // if (this.$route.query.modalIndex != undefined) {
    //   if (window.innerWidth < 768) {
    //     this.$refs["staySafeModal"].hide();
    //   } else {
    //     this.staySafeModalDetail = this.staySafeCard[
    //       Number(this.$route.query.modalIndex) - 1
    //     ];
    //     this.modalIndex = Number(this.$route.query.modalIndex);
    //     this.$refs["staySafeModal"].show();
    //   }
    // }
  },
  methods: {
    flowInformationHandler() {
      this.$router.push({ path: "/" });
    },
    favoriteInformationHandler() {
      this.$router.push({ path: "/favorites" });
    },
    myEventHandler(e) {
      if (window.innerWidth < 585) {
        this.sliderSetting.slidesToShow = 1;
      } else if (window.innerWidth >= 585 && window.innerWidth < 850) {
        this.sliderSetting.slidesToShow = 2;
      } else if (window.innerWidth >= 850 && window.innerWidth <= 1280) {
        this.sliderSetting.slidesToShow = 3;
      } else if (window.innerWidth > 1280 && window.innerWidth <= 1600) {
        this.sliderSetting.slidesToShow = 4;
      } else if (window.innerWidth > 1600) {
        this.sliderSetting.slidesToShow = 5;
      }
    },
    showModal(index) {
      if (window.innerWidth < 768) {
        this.$refs["staySafeModal"].hide();
      } else {
        this.$router.replace({
          path: "public-safety",
          query: { modalIndex: index + 1 },
        });
        this.modalIndex = Number(this.$route.query.modalIndex);
        this.staySafeModalDetail = this.staySafeCard[index];
        this.$refs["staySafeModal"].show();
      }
      // this.$router.push({
      //   path: "public-safety",
      //   query: { modalIndex: index + 1 },
      // });
      // this.modalIndex = Number(this.$route.query.modalIndex);
      // this.staySafeModalDetail = this.staySafeCard[index];
      // this.$refs["staySafeModal"].show();
    },
    titleHeadingOddColour() {
      if (
        this.modalIndex === 1 ||
        this.modalIndex === 2 ||
        this.modalIndex === 5
      ) {
        return "GreenHeading";
      } else {
        return "WhiteHeading";
      }
    },
    titleHeadingEvenColour() {
      if (
        this.modalIndex === 1 ||
        this.modalIndex === 2 ||
        this.modalIndex === 5
      ) {
        return "WhiteHeading";
      } else {
        return "GreenHeading";
      }
    },
  },
  computed: {
    isLoggedIn() {
      if (this.$localStorage.token == null) {
        return (this.$localStorage.token = "");
      } else {
        return this.$localStorage.token !== "";
      }
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style>
.safewaterBG {
  background-image: url("../assets/images/BGimg1.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.safewaterBG .SafetySection {
  width: calc(100% - 8%);
  margin: auto;
  padding-top: 10px;
  padding-bottom: 70px;
}
.safewaterBG .publicList {
  margin: 0 30px;
}
.safewaterBG .greatIcon svg {
  font-size: 24px;
  text-shadow: 0px 1px 10px #000;
  margin-left: 8px;
}
.safewaterBG img.publicLogo {
  width: 60px;
}
.safewaterBG .bg-info {
  background-color: transparent !important;
}
.safewaterBG .bg-info p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  color: #fff;
}
.safewaterBG .brookfield-section h1 {
  font-weight: 800;
  font-size: 53px;
  color: #fff;
  margin-top: 100px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.safewaterBG .brookfield-section button {
  color: #0f3557;
  font-weight: 600;
  font-size: 21px;
  background-color: #fff;
  padding: 14px 34px;
  border-radius: 13px;
  border: 3px solid #3581f7;
}
.safewaterBG .CardSection {
  margin: 10px 10px 10px 0;
}
.safewaterBG .cardBgSec {
  min-height: 220px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  margin-bottom: 20px;
  margin: 10px;
}
.safewaterBG .New-York {
  background-image: url("../assets/images/New-York.png");
}
.safewaterBG .Maine {
  background-image: url("../assets/images/Maine.png");
}
.safewaterBG .Pennsylvania {
  background-image: url("../assets/images/Pennsylvania.png");
}
.safewaterBG .West-Virginia {
  background-image: url("../assets/images/West-Virginia.png");
}
.safewaterBG .Massachusetts {
  background-image: url("../assets/images/Massachusetts.png");
}
.safewaterBG .Maryland {
  background-image: url("../assets/images/Maryland.png");
}
.safewaterBG .Tennessee {
  background-image: url("../assets/images/Tennessee.png");
}
.safewaterBG .North-Carolina {
  background-image: url("../assets/images/North-Carolina.png");
}
.safewaterBG .New-Hampshire {
  background-image: url("../assets/images/New-Hampshire.png");
}

.safewaterBG .cardBgSubSec {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  margin-bottom: 20px;
  margin: 10px;
}
.safewaterBG .cardBgSecOne {
  background-image: url("../assets/images/Card-img1.png");
}
.safewaterBG .cardBgSecTwo {
  background-image: url("../assets/images/Card-img2.png");
}
.safewaterBG .cardBgSecThree {
  background-image: url("../assets/images/Card-img3.png");
}
.safewaterBG .cardBgSecFour {
  background-image: url("../assets/images/Card-img4.png");
}
.safewaterBG .cardBgSecFive {
  background-image: url("../assets/images/Card-img5.png");
}
.safewaterBG h6.StayHeading {
  font-weight: 800;
  font-size: 20px;
  color: #fff;
  margin-top: 94px;
  margin-bottom: 21px;
}

.safewaterBG .cardBgSec h6 {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  padding: 26px 15px;
  line-height: 26px;
  text-transform: uppercase;
  white-space: initial;
}
.safewaterBG .CardRow {
  width: 100%;
}
.safewaterBG .publicList p {
  cursor: pointer;
  text-shadow: 0px 1px 10px #000;
}

.safewaterBG div#nav-collapse {
  display: block !important;
}

.safewaterBG .brookfieldLogoImage {
  width: 200px;
}
.safewaterBG .navbar-nav {
  flex-direction: row;
  justify-content: space-between;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: unset !important;
}
.safewaterBG .CardRow .publicModalBtn {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: default !important;
}
.safewaterBG .SafetySection .CardRow .publicModalBtn:hover,
:active,
:focus {
  border: 0;
  box-shadow: none !important;
}
.BgDiv {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: inherit;
  width: 100%;
  min-height: 100vh;
  background-position: center;
}
.BgDivOne {
  background-image: url("../assets/images/PortlandPressBG1.jpg");
}
.BgDivTwo {
  background-image: url("../assets/images/NorthCountryBG2.png");
}
.BgDivThree {
  background-image: url("../assets/images/LNPBG3.png");
  background-size: cover;
}
.BgDivFour {
  background-image: url("../assets/images/NorthCountryBG4.png");
  background-position: right !important;
}
.BgDivFive {
  background-image: url("../assets/images/NorthCountryBG5.png");
}
.BgDiv .HeadingSection {
  margin: 0 65px;
  padding-top: 5rem;
}

.BgDivThree .PublicTable {
  margin-top: 27rem;
}
.BgDivFive .HeadingSection {
  padding-top: 6rem;
}
.BgDivFive .PublicTable {
  margin-top: 38rem;
}
.BgDivOne .HeadingSection {
  padding-top: 12rem;
}
.BgDivTwo .HeadingSection {
  padding-top: 8rem;
}
.BgDivTwo .PublicTable {
  margin-top: 35rem;
}
.BgDiv .HeadingSection .GreenHeading {
  color: #3fab3c;
  font-size: 124px;
  font-weight: 800;
  line-height: 95px;
}
.BgDiv .HeadingSection .WhiteHeading {
  color: #fff;
  font-size: 124px;
  font-family: sans-serif;
  font-weight: 800;
  line-height: 95px;
}

.PublicTable {
  margin-top: 57rem;
  padding: 70px;
}
.PublicTable,
.PublicTable table#table1 {
  width: 100%;
}
.BgDivOne .PublicTable tr {
  font-size: 40px;
}
.BgDivOne .HeadingSection .GreenHeading,
.BgDivFive .HeadingSection .GreenHeading {
  font-size: 120px;
}
.BgDivOne .HeadingSection .WhiteHeading,
.BgDivFive .HeadingSection .WhiteHeading {
  font-size: 120px;
}
.BgDivFour .HeadingSection {
  padding-top: 8rem;
}
.PublicTable tr {
  border: 5px solid #fff;
  color: #fff;
  font-size: 35px;
  font-weight: 600;
}
.PublicTable td {
  width: calc(100% - 30%);
  color: #fff;
  border: 5px solid #fff;
  font-size: 35px;
  font-weight: 600;
}
.PublicTable .ImgTd {
  width: 100%;
}
.PublicTable #table1 .LinkDiv p {
  color: #fff;
  font-size: 29px;
  font-weight: 600;
}
.PublicTable .HeadTr p {
  width: calc(100% + 30%);
  padding: 10px 15px;
}
.PublicTable .ImgTd img {
  padding: 15px;
}
.privacy-safety-modal-content .modal-body {
  padding: 0;
}
.privacy-safety-modal-content .modal-header {
  border: 0 !important;
}
.privacy-safety-modal-content {
  background-color: transparent !important;
}
.modal-header .close {
  color: #fff;
  opacity: 1;
  border-radius: 50%;
  line-height: 0;
  padding: 15px 0px;
  padding-bottom: 22px;
}
.modal-header .close:hover {
  color: #fff;
}

/* Media */
@media (min-width: 393px) and (max-width: 420px) {
  .safewaterBG .cardBgSec h6 {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (min-width: 300px) and (max-width: 392px) {
  .safewaterBG .cardBgSec {
    background-position: center;
    min-height: 230px !important;
  }
  .safewaterBG .publicList {
    margin: 0;
    flex: 0 0 1%;
  }
}
@media (max-width: 769px) {
  .slick-slide {
    padding: 10px;
  }
  .slick-next {
    right: -5px;
  }
  .slick-prev {
    left: -5px;
    z-index: 15;
  }
}
@media (min-width: 320px) and (max-width: 393px) {
  .safewaterBG .cardBgSec {
    min-height: 300px;
  }
}
@media (max-width: 768px) {
  .stateSection .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .safewaterBG {
    background-position: center;
  }
  .safewaterBG .cardBgSec {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 330px;
  }
  .safewaterBG .brookfield-section h1 {
    font-weight: 800;
    font-size: 30px;
    margin-top: 50px;
  }
  .safewaterBG .brookfield-section button {
    font-size: 16px;
    padding: 10px;
  }
  .safewaterBG .SafetySection {
    padding-bottom: 70px;
  }
  .safewaterBG h6.StayHeading {
    margin-top: 50px;
    margin-bottom: 0;
  }
  .safewaterBG .CardSection {
    max-width: 100% !important;
    flex: auto !important;
    margin-bottom: 20px;
    width: 100% !important;
    margin-left: 0;
  }
  .safewaterBG div#nav-collapse {
    display: block !important;
  }
  .safewaterBG .publicList {
    margin: 0;
  }
  .safewaterBG .bg-info p {
    font-size: 16px;
  }
  .safewaterBG .navbar-nav {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .safewaterBG .brookfieldLogoImage {
    width: 100px !important;
  }
  /* Background Images Setting */
  .BgDivTwo {
    background-position: top;
    background-size: cover;
    min-height: 100vh;
    background-position: top;
  }
  .BgDivThree {
    background-position: -30px center;
    background-size: cover;
  }
  .BgDivFive {
    background-position: -170px center;
  }
  .BgDiv {
    background-attachment: fixed;
  }
  .BgDivTwo .PublicTable {
    margin-top: 20rem !important;
  }
  .BgDiv .HeadingSection {
    margin: 0 20px;
    padding-top: 2rem;
  }
  .BgDiv .HeadingSection .GreenHeading {
    font-size: 36px;
    line-height: unset;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 36px;
    line-height: unset;
  }
  .BgDiv .PublicTable {
    margin-top: 20rem;
    padding: 10px;
  }
  .BgDivOne .PublicTable tr {
    font-size: 20px;
  }
  .PublicTable tr {
    font-size: 20px;
  }
  .PublicTable td {
    font-size: 20px;
  }
  .PublicTable .HeadTr p {
    width: calc(100% + 45%);
    padding: 10px 15px;
  }
  .PublicTable #table1 .LinkDiv p {
    font-size: 21px;
    margin-top: 15px;
  }
  .PublicTable .ImgTd img {
    padding: 0;
  }
}
@media (min-width: 478px) and (max-width: 768px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 50% !important;
    max-width: 100%;
  }
}

@media (min-width: 585px) and (max-width: 769px) {
  .safewaterBG .cardBgSec {
    min-height: 210px;
  }
  .safewaterBG .cardBgSec h6 {
    line-height: 22px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 33%;
    max-width: 100%;
  }
  .safewaterBG {
    /* background-image: url(/img/BGimg1.e7706cd1.png); */
    background-repeat: no-repeat;
    min-height: 100vh;
    background-position: top;
    background-attachment: fixed;
  }
  .safewaterBG .brookfield-section h1 {
    font-size: 40px;
    margin-top: 65px;
  }
  .safewaterBG div#nav-collapse {
    display: block !important;
  }
  .safewaterBG .publicList {
    margin: 0;
  }
  .safewaterBG .navbar-nav {
    flex-direction: row;
    justify-content: space-between;
  }
  /* Background Images Setting */
  .BgDivOne {
    background-position: 135px center;
  }
  .BgDivTwo {
    background-position: -95px center;
  }
  .BgDivThree {
    background-position: 110px center;
  }
  .BgDivFour {
    background-position: -190px center;
  }
  .BgDivFive {
    background-position: -165px center;
  }

  .BgDiv .PublicTable {
    margin-top: 30rem !important;
  }

  .BgDiv {
    background-attachment: fixed;
  }
  .BgDiv .HeadingSection {
    margin: 0 65px;
    padding-top: 2rem;
  }
  .BgDiv .HeadingSection .GreenHeading {
    font-size: 45px;
    line-height: unset;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 45px;
    line-height: unset;
  }
  .BgDiv .PublicTable {
    margin-top: 25rem;
    padding: 10px;
  }
  .BgDivOne .PublicTable tr {
    font-size: 24px;
  }
  .PublicTable tr {
    font-size: 24px;
  }
  .PublicTable td {
    font-size: 24px;
  }
  .PublicTable .HeadTr p {
    width: calc(100% + 45%);
    padding: 10px 15px;
  }
  .PublicTable #table1 .LinkDiv p {
    font-size: 21px;
    margin-top: 15px;
  }
  .PublicTable .ImgTd img {
    padding: 0;
  }
}
@media (min-width: 910px) and (max-width: 920px) {
  /* .modal-dialog {
    max-width: 600px !important;
  } */
}
@media (min-width: 1400px) {
  .safewaterBG .SafetySection {
    padding-bottom: 150px;
  }
  .BgDivThree {
    background-position: inherit;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 33%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 25%;
    max-width: 100%;
  }
}
@media (min-width: 1300px) {
  .stateSection .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 20%;
    max-width: 100%;
  }
}
.locationIcon {
  margin-bottom: 10px;
  margin-right: 10px;
}
.card-body.card-img-overlay {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative !important;
  flex-direction: row-reverse;
  padding: 1.25rem 10px;
}
.card {
  background-color: transparent !important;
}
.card-title {
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  line-height: 26px;
  text-transform: uppercase;
  white-space: initial;
}
@media (min-width: 992px) and (max-width: 1025px) {
  .safewaterBG {
    /* background-image: url(/img/BGimg1.e7706cd1.png); */
    background-position: top;
    /* background-size: cover; */
    background-attachment: fixed;
    min-height: 100vh;
  }
  .BgDiv {
    background-attachment: fixed;
  }
  .BgDivThree {
    background-size: cover;
  }

  .BgDiv .HeadingSection .GreenHeading {
    font-size: 80px;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 80px;
  }
  .BgDiv .HeadingSection .GreenHeading {
    font-size: 90px;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 90px;
  }
}
@media (min-width: 768px) {
  .BgDivFour .PublicTable {
    margin-top: 35rem;
  }
  .BgDivThree .HeadingSection {
    padding-top: 8rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .safewaterBG {
    /* background-image: url(/img/BGimg1.e7706cd1.png); */
    background-position: center;
    min-height: 100vh;
  }
  .safewaterBG .cardBgSec {
    background-position: center;
  }
  .BgDivThree {
    background-position: -25px center;
  }
  .BgDiv .HeadingSection .GreenHeading {
    font-size: 90px;
  }
  .BgDiv .HeadingSection .WhiteHeading {
    font-size: 90px;
  }
  .BgDivOne .HeadingSection .GreenHeading,
  .BgDivFive .HeadingSection .GreenHeading {
    font-size: 77px;
  }
  .BgDivOne .HeadingSection .WhiteHeading,
  .BgDivFive .HeadingSection .WhiteHeading {
    font-size: 77px;
  }
}
</style>
