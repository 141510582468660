<template>
  <div>
    <header class="MainHeader">
      <router-link to="/" class="logo"
        ><img src="../assets/images/logo.png" alt="Safe-waters"
      /></router-link>
      <ul class="nav-custom">
        <li>
          <router-link to="/list/all"
            ><img src="../assets/images/to-do-list.png" alt="List-View" /> List
            View</router-link
          >
        </li>
        <li>
          <router-link to="/map"
            ><img src="../assets/images/map-icon.png" alt="Map-View" /> Map
            View</router-link
          >
        </li>
        <li>
          <a
            v-if="!isLoggedIn"
            href="javascript:void(0);"
            data-backdrop="static"
            data-keyboard="false"
            data-toggle="modal"
            data-target="#safelogin"
            ><img src="../assets/images/star.png" alt="Favorites" />
            Favorites</a
          >
        </li>
        <li v-if="isLoggedIn">
          <router-link to="/favorites"
            ><img src="../assets/images/star.png" alt="Favorites" />
            Favorites</router-link
          >
        </li>
        <li>
          <router-link to="/public-safety">Public Safety</router-link>
        </li>
        <!-- <li>
          <router-link to="/terraform">Terra Form</router-link>
        </li> -->
      </ul>
      <div style="display: none">
        <button id="ot-sdk-btn" class="ot-sdk-show-settings">
          Cookie Settings
        </button>
      </div>
      <div class="bottomtext">
        <router-link to="/terms" class="termlink"
          ><img src="../assets/images/lock-icon.png" alt="Favorites" /> Privacy
          & Term of use</router-link
        >
        <router-link to="" class="termlink ads"
          >Do Not Sell or Share My Personal Information</router-link
        >

        © {{ currentYear }}
        <a target="_blank" href="https://brookfieldrenewableus.com/"
          ><img src="../assets/images/brookfieldus-logo.png" alt="brookfieldus"
        /></a>
      </div>
    </header>
    <div class="safenotic safenotic-width" v-if="showWarning">
      <i class="fa fa-exclamation-triangle"></i>
      <p>
        <b>CAUTION</b> - {{ currentDate }} - We are experiencing difficulties
        and water data may not be up to date. We are working to resolve the
        issue as soon as possible.
      </p>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "HeaderComponent",
  data() {
    return {
      showWarning: false,
      currentDate: "",
      shortAbbr: "",
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    isLoggedIn() {
      if (this.$localStorage.token == null) {
        return (this.$localStorage.token = "");
      } else {
        return this.$localStorage.token !== "";
      }
    },
  },
  mounted() {
    this.$eventBus.$on("socket-event-header", (data) => {
      console.log(">>>>>>>>>>>Header>>>>>>>>>>", data);
      if (data.type === "global-level-warning") {
        this.showWarning = data.data;
        this.currentDate =
          moment(new Date()).format("dddd, MMMM DD, YYYY, hh:mm A") +
          " " +
          this.shortAbbr;
      }

      if (data.type === "healthy") {
        this.showWarning = data.data || false;
      }
    });

    const abbr = String(String(new Date()).split("(")[1]).split(")")[0];
    var first_letter = function (x) {
      if (x) {
        return x[0];
      } else {
        return "";
      }
    };

    this.shortAbbr = abbr.split(" ").map(first_letter).join("");

    document.querySelector(".ads").addEventListener("click", function (event) {
      document.querySelector(".ot-sdk-show-settings").click();
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("socket-event-header");
  },
};
</script>

<style>
#ot-sdk-btn-floating.ot-floating-button {
  animation: unset !important;
  opacity: 1 !important;
  right: 0 !important;
}
</style>
