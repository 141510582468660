<template>
  <div class="riverlist">
    <a
      href="javascript:void(0)"
      class="backlink"
      v-on:click="backToRiverSystem()"
    >
      <i class="fa fa-chevron-circle-left"></i>
      {{ riverSystemName }}</a
    >
    <div class="viewrecent">
      <div v-if="showAlertfac">
        <b-alert show variant="danger">facility not found</b-alert>
      </div>
      <div id="infinite-facility-id" class="facility-scroll-div">
        <div v-for="facilities of facilitiesList" v-bind:key="facilities.id">
          <router-link :to="'/facility/' + facilities.slug">
            <div class="row rfacilities">
              <div class="col-md-1">
                <div
                  class="dropsicon"
                  v-bind:class="
                    facilities.alertCount > 0
                      ? 'orange-alert'
                      : facilities.notificationCount > 0
                      ? 'green-notification'
                      : ''
                  "
                >
                  <img
                    src="../assets/images/water-drops.png"
                    alt="Facilities"
                  />
                </div>
              </div>
              <div class="col-md-5">
                <h1>{{ facilities.name }}</h1>
                <p>
                  <i class="fa fa-map-marker"></i> Near
                  {{ facilities.nearTown }}
                </p>
              </div>
              <div
                class="col-md-3"
                v-for="(matrices, index) of facilities.tb_facilityMatrices"
                v-bind:key="index"
              >
                <h1>
                  {{ matrices.value || "0" }}
                  <small>{{ matrices.unit }}</small>
                </h1>
                <p>{{ matrices.description }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import moment from "moment";
import customInstance from "../config";
export default {
  name: "ListFacility",
  data() {
    return {
      facilitiesList: [],
      errors: [],
      showAlertfac: false,
      limit: 10,
      hasMorefacility: false,
      pageFacility: 1,
      riverSystemName: "",
    };
  },
  methods: {
    getFacilities: function () {
      let params = {
        limit: this.limit,
        page: this.pageFacility,
      };

      if (this.$route.path.includes("search")) {
        params.search = this.$route.params.riverSystemSlug;
      } else {
        params.riverSystemId = this.$route.params.riverSystemSlug;
      }

      customInstance
        .get("/facilities", { params })
        .then((response) => {
          if (response.data.status == 200) {
            this.facilitiesList = this.facilitiesList.concat(
              response.data.data
            );
            this.riverSystemName = response.data.riverSysytem
              ? response.data.riverSysytem.name
              : "Back";

            document.title =
              this.riverSystemName +
              ", " +
              this.facilitiesList[0].region +
              " - Water Release Information | SafeWaters by Brookfield Renewable US";
            this.showAlertfac = false;
            this.FacilityLoaded();

            if (this.facilitiesList.length >= response.data.totalCount) {
              this.hasMorefacility = false;
            } else {
              this.hasMorefacility = true;
            }
          }
          if (response.data.status == 400) {
            this.facilitiesList = [];
            this.showAlertfac = true;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    FacilityLoaded() {
      this.$nextTick(() => {
        const listElm = document.querySelector("#infinite-facility-id");
        listElm.addEventListener("scroll", (e) => {
          if (
            listElm.scrollTop + listElm.clientHeight >=
            listElm.scrollHeight
          ) {
            this.loadMoreFacility();
          }
        });
      });
    },
    loadMoreFacility() {
      const facid = this.$route.query.facid;
      const name = this.$route.query.facname;
      if (this.hasMorefacility) {
        this.pageFacility = this.pageFacility + 1;
        this.getFacilities(facid, name);
      }
    },
    backToRiverSystem() {
      if (this.$route.path.includes("search")) {
        this.$router.push("/list/all");
      } else {
        this.$router.push("/list/" + this.$route.params.regionSlug);
      }
    },
  },
  created() {
    this.getFacilities();
  },
  mounted() {
    this.$eventBus.$on("socket-event", (data) => {
      if (data.type === "flow") {
        let index = this.facilitiesList.findIndex(
          (item) => item.id === data.facilityId
        );
        if (index > -1) {
          const temp = data.data.sort(function (x, y) {
            return y.updateOn - x.updateOn;
          });
          this.facilitiesList[index].tb_facilityMatrices = temp.slice(0, 2);
        }
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("socket-event");
  },
  watch: {
    $route: function (to, from) {
      this.pageFacility = 1;
      this.facilitiesList = [];
      this.errors = [];
      this.getFacilities();
    },
  },
};
</script>

<style type="text/css">
.facility-scroll-div {
  height: 500px;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .facility-scroll-div {
    max-height: 500px;
    height: auto;
  }
}
</style>
