<template>
  <main>
    <section class="topsection">
      <div class="row m-0">
        <div
          class="col-md-4 col-lg-3 pt-5"
          style="background: rgb(15 53 87 / 3%)"
        >
          <ul class="listtext mt-5">
            <li><router-link to="/terms">Terms of Use</router-link></li>
            <li>
              <router-link to="/terms-data-protection" class="active"
                >Data Protection & Privacy</router-link
              >
            </li>
            <li @click="redirectToDataScreen()">
              <router-link to="">About Our Ads</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-8 col-lg-9 pt-5">
          <div class="contentt pb-5 mt-5">
            <h3 class="mb-5">U.S. Data Protection Policy and Privacy Notice</h3>

            <h4>1. INTRODUCTION</h4>

            <p>
              This U.S. data protection policy and privacy notice (“Policy”)
              describes how Brookfield Renewable (as defined below) collects,
              processes, and uses Personal Data (as defined under applicable
              law), including when you use our websites (as defined below),
              contact us, or provide us with certain Personal Data. You can also
              view our Website Terms of Use
              <a href="javascript:void(0);" @click="navigationHandler()">
                here</a
              >.
            </p>
            <p>
              Other policies may apply instead, depending on how you interact
              with us. If you are a Brookfield Renewable employee and a
              California resident, please see our California Employee Data
              Protection and Privacy Notice. If you submit an employment
              application to Brookfield Renewable and are a California resident,
              please see our California Applicant Data Protection Policy and
              Privacy Notice, available
              <a
                href="https://brookfieldrenewableus.com/california-applicant-data-protection-policy-and-privacy-notice"
                target="_blank"
                >here</a
              >.
            </p>

            <p>
              This Policy may change from time to time, and you should review it
              periodically. This Policy was last updated in July 2024.
            </p>

            <p>
              If you are a California resident, click
              <a href="#sectionTen"> here</a>. to view “Your California Privacy
              Rights” in Section 10 and our notice at the collection for
              information provided pursuant to California law.
            </p>

            <h4>2. DEFINITIONS</h4>

            <p>The following definitions shall apply to this Policy:</p>

            <p>
              “Brookfield Renewable”, “we”, “us”, “our” means entities owned,
              operated, and/or managed by Brookfield Renewable Partners, LP that
              conduct material business activities in the United States.
            </p>
            <p>
              “Websites” means Brookfield Renewable websites that link to this
              Policy.
            </p>

            <h4 id="sectionThree">
              3. THE TYPES AND SOURCES OF PERSONAL DATA WE COLLECT
            </h4>

            <p>
              Where this Policy applies, Brookfield Renewable may collect and
              process the following categories of Personal Data about you,
              depending on how you interact with us, and from the sources
              identified as follows:
            </p>

            <ol type="a">
              <li>
                <strong
                  >We collect Contact Information and Demographic
                  Information.</strong
                >
                We collect and process Personal Data directly from you,
                including, but not limited to, name, age, date of birth,
                address, gender, nationality, citizenship, phone number, email
                address, and contact information. We collect business contact
                information from our business customers, including a job title,
                company name, and business email or phone number. On some
                Websites, we collect a username and password if you create an
                account. We may also collect information related to your
                recreational or other use of a Brookfield Renewable project
                site. We may collect this information when you sign up for our
                emails, attend an event, contact us, or submit a request to us.
                Our vendors and service providers may also give us this
                information. Please note that if you do not provide certain
                Personal Data to Brookfield Renewable when requested (and where
                relevant, provide your consent) we may not be able to provide
                you with access to all areas of the Website and associated
                services.
              </li>

              <li>
                <strong
                  >We collect Device, Internet, and Network Activity
                  Information.</strong
                >
                We collect technical information passively through cookies, web
                beacons, pixels, tags, and other tracking tools (see below)
                about the services that you use and how you use them. We may
                also collect this information through our ads and emails we
                send. This technical information includes device identifiers, IP
                address, your navigation throughout the Websites, how often and
                when you visit, and other technical and browsing preferences
                including your general location and entry point to the Websites.
                We collect information about your browser, operating system, and
                Internet connection, and the pages you visit. We collect
                information about users over time and across different websites
                when you use the Website. For example, we may look at what site
                you came from, or what site you visit when you leave us. Third
                parties help us collect personal information this way, including
                Google Analytics. Learn more about how you can control these
                tools in Section 10,
                <a href="#sectionTen"> “Your Privacy Rights and Choices”</a>
                below.
              </li>

              <li>
                <strong>We collect Financial Information.</strong> We collect
                financial account numbers, payment routing information, and
                government ID numbers from our business contacts. Our vendors
                and service providers may also give us this information.
              </li>

              <li>
                <strong>We collect Location Information.</strong> We collect
                information about your general location, using your address, ZIP
                code, and IP address.
              </li>
              <li>
                <strong>We collect Identity Verification Information.</strong>
                For security purposes, we may collect identity verification
                information from you, such as images of your government issued
                ID, passport, national ID card, or driving license, as permitted
                by applicable laws, or other authentication information.
              </li>
              <li>
                <strong
                  >We collect other Personal Data you submit or send to
                  us.</strong
                >
                We may collect Personal Data that you provide when you contact
                Brookfield Renewable for any reason, such as to express an
                interest in obtaining additional information about our services,
                direct questions or concerns about our services to us, use a
                &quot;Contact Us&quot; form or similar features, sign up for our
                emails or attend an event, or download certain content. We
                collect information you provide in connection with a survey
                response. Such information may include contact information such
                as name, job title, company name, phone number, and email
                address. It may also include information about how you use
                different Brookfield Renewable project sites – for example, for
                recreation.
              </li>
              <li>
                <strong>We collect Reputation & Background Check Data.</strong>
                We may collect Personal Data from you and from third parties in
                connection with performing diligence on our service providers
                and business partners. This data may include contact details,
                information concerning business practices, creditworthiness,
                reputation and business history, and job titles or roles.
              </li>
              <li>
                <strong>We collect Inferences.</strong> We collect and draw
                inferences about you, such as your interests, your preferences,
                and your characteristics, using Personal Data collected about
                you.
              </li>
            </ol>

            <p>
              Some of this information (including financial account information,
              account login information, and government ID numbers) may be
              considered “sensitive” under the laws of certain jurisdictions.
              Whether information is sensitive information will depend on the
              laws of your jurisdiction.
            </p>

            <h4 id="sectionFour">4. HOW WE USE YOUR PERSONAL DATA</h4>

            <p>
              Each of the above categories of Personal Data may be collected,
              stored, disclosed and processed by Brookfield Renewable as
              permitted by law and for the following business purposes:
            </p>
            <ol type="a">
              <li>
                to provide you with marketing communications and inform you
                about news and information relating to our business and
                services, including new product launches, product updates and
                general business news (see Section 10 below for further
                information);
              </li>

              <li>
                to assess your application for Brookfield Renewable&#39;s
                products and services, where applicable;
              </li>

              <li>
                to understand your needs and interests and to respond to your
                enquiries;
              </li>

              <li>to analyze and improve our services;</li>

              <li>for the management and administration of our business;</li>

              <li>
                to provide you with the products and services for which you
                subscribe;
              </li>

              <li>
                to comply with and in order to assess compliance with applicable
                laws, rules and regulations (including tax reporting purposes
                pursuant to tax legislation), industry codes, voluntary codes we
                decide to adopt, or good practice, anywhere in the world and
                internal policies and procedures including the Websites Terms of
                Use;
              </li>

              <li>
                to confirm and verify your identity (this may be for a privacy
                rights request, or it may involve the use of a credit reference
                agency or other third parties acting as our agents) and to
                conduct due diligence, background and related checks. We will
                also screen against publicly available government and/or law
                enforcement agency sanctions lists. We may use third party
                providers to conduct these verifications and searches;
              </li>

              <li>
                to detect, investigate and prevent fraud and other crimes or
                malpractice.
              </li>

              <li>
                for the purpose of, or in connection with, any legal proceedings
                (including prospective legal proceedings), or to communicate
                with you about this Policy or our Website Terms of Use;
              </li>

              <li>
                to obtain legal advice or to establish, exercise or defend legal
                rights;
              </li>

              <li>
                the administration and maintenance of databases storing Personal
                Data;
              </li>

              <li>to comply with our contractual obligations;</li>

              <li>
                for ongoing review and improvement of the information, content
                and services provided on the Websites to ensure they are user
                friendly and to prevent any potential disruptions such as
                cyber-attacks;
              </li>

              <li>
                to analyze and report on the Websites traffic, marketing and
                usage trends;
              </li>

              <li>
                to allow you to use and access functionality provided by the
                Websites;
              </li>

              <li>
                to conduct analysis required to detect malicious data and
                understand how this may affect your IT system;
              </li>

              <li>
                for statistical monitoring and analysis of current attacks on
                devices and systems for the on- going adaption of the solutions
                provided to secure systems and devices against current attacks;
              </li>

              <li>for in-depth threat analysis;</li>

              <li id="test-02">
                for targeted advertising and interest-based ads. We and our
                third-party advertising partners display targeted advertising,
                cross-context behavioral advertising, and interest-based
                advertising using device, network, and other activity
                information and inferences from cookies and tracking tools
                gathered over time and across websites, apps, or platforms.
                These ads can be served on websites, on social media, in apps,
                and in emails. The ads may be based on your browsing habits,
                purchases, location, or online activities. The ads can be about
                our products or other companies’ products and services. We and
                our third-party partners gather information about you for this
                purpose from the cookies, pixels, tags, web beacons, and other
                tracking tools described in this Policy. Our third-party
                partners might also link your name or email address to other
                information they collect. Learn more about these third-party
                partners in <a href="#sectionFive"> Section 5 (e)</a>, below.
                Sharing your information in connection with these advertising
                activities may be considered a “sale” or “sharing” as defined
                under certain privacy laws. Learn more about your choices for
                this kind of advertising in Section 10,<a href="#sectionTen"
                  >“Your Privacy Rights and Choices,”
                </a>
                below;
              </li>

              <p>
                to combine information. We may combine information collected
                online with information collected offline. We may combine
                information that we get from another party with information we
                already have. We may also aggregate your information with other
                individuals’ information to understand preferences and trends
                over time; and
              </p>

              <li>
                for purposes we disclose when we collect your information, or as
                otherwise set out in this Policy.
              </li>
            </ol>

            <p>
              <strong
                >We use Sensitive Information for necessary or reasonably
                expected purposes –</strong
              >
              specifically, to provide you with services (i.e., to accept and
              make payments or log in to our Website), for tax purposes, and as
              authorized by law.
            </p>
            <p>
              We may use de-identified or aggregated data for any purpose,
              unless prohibited by law. We do not attempt to re-identify
              de-identified data, except as permitted or required by law.
            </p>
            <p>
              If you are located in a jurisdiction which requires us to obtain
              your consent to collect, use, store, process and disclose your
              Personal Data, you understand that by accepting the terms of this
              Policy you expressly provide your consent to Brookfield Renewable
              collecting, using, storing, processing and disclosing your
              Personal Data as set out in this Policy.
            </p>
            <h4 id="sectionFive">5. DISCLOSURE OF YOUR PERSONAL DATA</h4>

            <p>
              Brookfield Renewable may share or provide access to your Personal
              Data among its affiliates and business units and to third parties,
              agents, service providers, and contractors outside of Brookfield
              Renewable:
            </p>

            <ol type="a">
              <li>
                for the purpose of the management and administration of
                Brookfield Renewable&#39;s business;
              </li>

              <li>
                in order to facilitate the provision and enhancement of services
                to you (including the management of your project);
              </li>

              <li>
                for the purpose of the administration and maintenance of the
                databases storing Personal Data;
              </li>

              <li>
                for the purposes of Brookfield Renewable receiving services (for
                example, Brookfield Renewable&#39;s accountants, administrators,
                auditors, service providers, custodians, depositories, third
                party managers, paying agents, professional advisors, IT and
                communications providers or any entity we reasonably consider
                necessary for the purposes outlined above). These service
                providers will be expected to be subject to confidentiality
                requirements (either by contract, professional obligation, duty
                or otherwise) that require them to only use your Personal Data
                as described above;
              </li>

              <li>
                With advertising partners for marketing purposes. We share
                information with third-party advertising partners through
                tracking tools on our Websites. We may also share email
                addresses or other contact information with third-party partners
                so they can serve our advertising on their platform. These
                third-party partners include social media platforms, analytics
                companies, advertising networks, data brokers, and advertising
                technology companies. Personal information received by these
                companies may also be subject to their privacy policies. These
                third parties may also share your information with others. These
                third parties may use the information for their own marketing
                purposes or the marketing purposes of others. This may include
                the delivery of targeted advertising, cross-context behavioral
                advertising, and interest-based advertising.
              </li>

              <li>
                to the extent required by law (for example, if Brookfield
                Renewable is compelled by an obligation or a duty to disclose
                your Personal Data where we believe it is necessary or
                appropriate to comply with any legal obligation, rule,
                regulations or internal Brookfield Renewable policies and
                procedures, including (without limitation) in order to comply
                with tax reporting requirements and other statutory reporting
                and disclosures to regulatory authorities), or to establish,
                exercise or defend its legal rights. This may include disclosure
                to regulatory bodies or government agencies and in order to
                investigate unauthorized attempts to modify the Websites,
                install harmful files or cause damage to the Websites or to
                Brookfield Renewable);
              </li>

              <li>
                as part of a transaction, financing, or for other business needs
                (for example, if Brookfield Renewable sells any of its
                businesses or assets, applies for a loan, or opens bank
                accounts, in which case Brookfield Renewable may need to
                disclose your Personal Data to the prospective buyer, lender or
                bank, as the case may be, as part of certain due diligence
                processes); or
              </li>
              <li>
                if Brookfield Renewable or any of its affiliates, divisions or
                business units is acquired by a third party, including in the
                unlikely event of a bankruptcy, in which case the Personal Data
                held by Brookfield Renewable about you will be accessible to,
                and may be acquired by, the third party buyer.
              </li>
            </ol>

            <p>
              Brookfield Renewable may share de-identified or aggregated data
              with third parties such as service providers for any purpose,
              including in order to facilitate our business operations.
            </p>

            <h4>6. LINKS TO OTHER WEBSITES</h4>

            <p>
              The Websites may contain links to other party websites that are
              not governed by this Policy. Linked sites may have their own
              privacy notices or policies, which you should review. Brookfield
              Renewable is not responsible for the content of links or
              third-party websites and your use of such third-party websites is
              at your own risk.
            </p>

            <h4>7. TRANSFERS OF PERSONAL DATA</h4>

            <p>
              Brookfield Renewable operates globally. This means Personal Data
              may be processed and disclosed as described above, in any country
              in which we conduct business or have a service provider.
              Accordingly, when you provide your Personal Data to Brookfield
              Renewable, you acknowledge and agree that we may disclose your
              Personal Data to recipients (including, but not limited to service
              providers, Brookfield Renewable affiliates or agents, and
              Brookfield Renewable IT servers) located in jurisdictions other
              than your own, including but not limited to Canada. In other
              circumstances, data protection and privacy laws may permit
              Brookfield Renewable to otherwise transfer your Personal Data
              outside your jurisdiction provided it is in compliance with such
              data protection and privacy laws.
            </p>

            <h4>8. HOW WE SAFEGUARD YOUR PERSONAL DATA</h4>

            <p>
              Brookfield Renewable has implemented commercially reasonable
              controls and appropriate technical and organizational measures to
              protect Personal Data, as well as to maintain the security of our
              information and information systems in respect of Personal Data,
              but the Internet is not 100% secure. We cannot promise that your
              use of our Websites will be completely safe.
            </p>

            <p>
              When you contact a Brookfield Renewable employee about your file,
              you may be asked to confirm some details relating to the Personal
              Data Brookfield Renewable holds about you. This type of safeguard
              is designed to ensure that only you, or someone authorized by you,
              has access to your file.
            </p>

            <h4>9. RETENTION AND DESTRUCTION OF PERSONAL DATA</h4>

            <p>
              The period for which Brookfield Renewable will hold your Personal
              Data will vary and will be determined by the following criteria:
            </p>
            <ol type="a">
              <li>
                The purpose for which Brookfield Renewable is using it.
                Brookfield Renewable is required to retain the Personal Data for
                as long as is necessary to satisfy or meet the purposes for
                which it was obtained including applicable legal or regulatory
                requirements, the length of our relationship with you, your
                requests, your continued use of our Websites, and ongoing
                obligations such as contracts and services; and
              </li>

              <li>
                Legal Obligations. Laws or regulations may set a minimum period
                for which Brookfield Renewable must retain your Personal Data.
              </li>
            </ol>

            <p>
              Depending on the requirements of the data protection law of your
              jurisdiction, Brookfield Renewable will take reasonable steps
              using appropriate technical methods in the circumstances to delete
              or destroy your Personal Data when we no longer have a legal basis
              to retain it or to ensure that the information is anonymized or
              irrecoverable.
            </p>

            <h4 id="sectionTen">10. YOUR PRIVACY RIGHTS AND CHOICES</h4>

            <p>
              <strong>You can opt out of our marketing communications.</strong>
              To stop receiving our marketing emails, follow the instructions in
              any marketing email you get from us. Even if you opt out of
              receiving marketing emails, we will still send you transactional
              messages. These include responses to your questions and requests.
            </p>
            <p>
              <strong
                >Residents of certain US states can exercise privacy
                rights.</strong
              >To the extent provided by the law of your jurisdiction, you may
              have legal rights in relation to the Personal Data about you that
              Brookfield Renewable holds. Brookfield Renewable honors rights
              requests as and to the extent required under applicable laws based
              on your state of residence. If you live in California, Colorado,
              Connecticut, Montana, Oregon, Texas, Utah, or Virginia, you (or
              your authorized agent) can:
            </p>
            <ul>
              <li>
                <strong>Ask for access to your Personal Data.</strong> We will
                provide access to your personal information in a portable and
                (to the extent technically feasible) a readily usable format in
                accordance with applicable law. You can also request to confirm
                the processing of your personal information, to know the third
                parties to which we disclosed personal information (for Oregon
                residents), and to request to revoke your prior consent.
              </li>

              <li>
                <strong>Ask us to delete your Personal Data.</strong>
                Please note that your information may not be deleted in its
                entirety from our systems, and some information may be kept to
                comply with legal exceptions or obligations. We may also keep an
                archived copy of your records as required by law or for
                legitimate business purposes.
              </li>

              <li>
                <strong>Ask us to correct your Personal Data.</strong>
              </li>

              <li>
                <strong>Appeal our decisions,</strong>
                if you are not satisfied with the outcome of your request.
                Please send appeals by email with “Attention: Privacy Appeal” in
                the subject line.
              </li>

              <li>
                <strong>How to submit:</strong>
                If you are eligible to make a request, you can do so by email at
                Privacy@BrookfieldRenewable.com, via Webform
                <a
                  href="https://privacyportal.onetrust.com/webform/36e871fb-d2ae-4c90-8f27-c24783bf4227/dd7d1b5b-df42-41dc-a6fc-8d3c6b14e96e"
                  target="_blank"
                >
                  HERE </a
                >or by phone at
                <a href="tel:+1 844-749-2387">+1 844-749-2387</a>. We will use
                commercially reasonable efforts to honor your request in
                accordance with applicable law, and we will verify your identity
                by email. If you have an authorized agent submit a request on
                your behalf, we may still verify your identity and their
                authority to submit a request for you. We will not discriminate
                against you for exercising any of the rights outlined in this
                Policy. California residents can also view additional
                information and our notice at collection under
                <a
                  href="https://brookfieldrenewableus.com/california-applicant-data-protection-policy-and-privacy-notice"
                  target="_blank"
                  >“Your California Privacy Rights,”</a
                >
                below.
              </li>
            </ul>

            <p>
              <strong
                >You can opt-out of sale / targeted advertising on our
                Websites.</strong
              >
              You can opt out of the “sale” of your personal information, as
              well as certain types of “targeted advertising” on our Website (as
              these terms are defined under applicable laws and as described in
              Sections 4 and 5). Your request will be processed and fulfilled in
              accordance with applicable laws. To make a request, click here, or
              in the footer of our Site.
            </p>
            <p>
              <strong
                >You can control certain cookies and tracking tools.</strong
              >Your browser or device may give you the ability to control
              cookies or other tracking tools. How you do so depends on the
              browser. To control flash cookies, you can go
              <a
                href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                target="_blank"
              >
                here.</a
              >
              Why? Because flash cookies cannot be controlled through your
              browser settings. The Digital Advertising Alliance also offers
              tools to opt out of certain interest-based advertising by visiting
              <a href="https://optout.aboutads.info" target="_blank"
                >optout.aboutads.info</a
              >
              and
              <a href="https://optout.privacyrights.info" target="_blank"
                >optout.privacyrights.info.</a
              >
              If you block or reject cookies and tracking tools, certain
              features on our Websites may not work, and not all of the tracking
              describe in this Policy will stop.
            </p>
            <p>
              <strong>Our Do Not Track Policy:</strong>Some browsers have “do
              not track” features that allow you to tell a website not to track
              you. We will respond to opt-out preference signals to the extent
              required by law for your browser or device (such as Global Privacy
              Control,
              <a href="https://globalprivacycontrol.org" target="_blank">
                https://globalprivacycontrol.org</a
              >, but we cannot link this information to your account and we do
              not respond to “do not track” signals. Your selections are
              specific to the device, website, and browser you are using, and
              are deleted whenever you clear your browser’s cache.
            </p>
            <p>
              <strong>Google Analytics:</strong> Websites use Google Analytics,
              including certain advertising features to better understand our
              users, create audiences for our ads, and to evaluate and improve
              our platforms. For more information on how to opt out of these
              tools, please visit https://tools.google.com/dlpage/gaoptout and
              <a href="https://safety.google" target="_blank"
                >http://www.google.com/safetycenter/tools/#manage-your-ads-preferences.</a
              >
            </p>

            <p>YOUR CALIFORNIA PRIVACY RIGHTS</p>
            <p>
              If you are a California resident, you have the right to request
              certain information from us regarding the manner in which we share
              with third-parties certain categories of information that
              identifies you. Brookfield Renewable does not share Personal Data
              with third parties for those third parties’ own direct marketing
              purposes.
            </p>

            <p>
              If you live in California, you have the right to make the
              following more specific requests with respect to your Personal
              Data. We will not discriminate against you for making any of these
              requests.
            </p>

            <ul>
              <li>
                <strong>Know / Access</strong> – You have the right to request
                that we disclose to you the categories of Personal Data we
                collected about you, the categories of sources from which we
                collected the Personal Data, the categories of Personal Data we
                sold or disclosed, our business or commercial purpose for
                collecting, selling, or sharing the Personal Data, the
                categories of third parties to whom we disclosed the Personal
                Data, and the specific pieces of Personal Data we collected
                about you.
              </li>

              <li>
                <strong>Deletion</strong> – You have the right to request that
                Brookfield Renewable delete your Personal Data, except in
                circumstances where Brookfield Renewable is legally entitled to
                retain Personal Data regardless of any such request.
              </li>

              <li>
                <strong>Correction</strong> – You can ask us to correct
                inaccurate Personal Data that we maintain about you.
              </li>

              <li>
                <strong>Do Not Sell or Share My Personal Data</strong> – You
                have the right to opt out of the sale or sharing of your
                Personal Data to any third parties by visiting our Do Not Sell
                or Share My Information page. For purposes of this Policy,
                “sell” means the sale of your personal information to an outside
                party for monetary or other valuable consideration, and
                “sharing” means any disclosure of personal information (renting,
                releasing, disclosing, disseminating, making available,
                transferring, or otherwise communicating orally, in writing, or
                by electronic or other means) to third parties for
                cross-contextual behavioral advertising purposes; in each case,
                subject to certain exceptions under California law. Your
                selections are specific to the device, website, and browser you
                are using, and are deleted whenever you clear your browser’s
                cache.
              </li>
            </ul>

            <P>
              If you are a California resident, Personal Data will include the
              full definition of “Personal Information” under the California
              Consumer Privacy Act.
            </P>

            <P>
              <strong>How to Submit:</strong> If you wish to exercise any of the
              rights enumerated in this section, please contact us at
              Privacy@BrookfieldRenewable.com, via webform
              <a
                href="https://privacyportal.onetrust.com/webform/36e871fb-d2ae-4c90-8f27-c24783bf4227/dd7d1b5b-df42-41dc-a6fc-8d3c6b14e96e"
                target="_blank"
                >HERE </a
              >or by phone at <a href="tel:+1 844-749-2387">+1 844-749-2387</a>.
            </P>

            <p>
              <strong>Additional Information:</strong> We may deny certain
              requests, or fulfill a request only in part, based on our legal
              rights and obligations. For example, we may retain personal
              information as permitted by law, such as for tax or other record
              keeping purposes, to maintain an active account, and to process
              transactions and facilitate consumer requests. We will take
              reasonable steps to verify your identity prior to responding to
              your requests. The verification steps will vary depending on the
              sensitivity of the personal information and whether you have an
              account with us. California residents may designate an authorized
              agent to make a request on their behalf. When submitting the
              request, please ensure the authorized agent is identified as an
              authorized agent.
            </p>

            <p>
              <strong>Additional Disclosures:</strong> For purposes of
              exercising these rights, please note the following regarding how
              we collect and use your Personal Data as described in this Policy:
            </p>

            <ul>
              <li>
                We may collect and use, for our business and commercial
                purposes, the following categories of Personal Data:
                Identifiers; payment information; Internet or other electronic
                network activity information; demographic information;
                commercial information; professional or employment information;
                inferences; and geolocation data. For more information, see
                <a href="#sectionThree"> Section 3</a>.
              </li>
              <li>
                We collect and use these categories of personal information for
                the business and commercial purposes described in
                <a href="#sectionFour"> Section 4</a> of this Policy. We collect
                these categories of personal information from the sources
                described in <a href="#sectionThree"> Section 3</a> of this
                Policy. To the extent permitted by applicable law, we may
                disclose these categories of personal information for the
                business and commercial purposes described in
                <a href="#sectionFour"> Section 4</a> of this Policy to the
                categories of third parties described in
                <a href="#sectionFive"> Section 5</a> of this Policy.
              </li>
              <li>
                We may “sell” or “share” the following categories of personal
                information: Identifiers; Internet or other electronic network
                activity; demographic information; inferences; and geolocation
                data. We may “sell” or “share” these categories of personal
                information to the social media platforms, advertising networks,
                analytics companies, and ad tech companies described in
                <a href="#sectionFive">Section 5(e)</a> of this Policy (to the
                extent permitted by applicable law).
              </li>
            </ul>

            <h4>11. CHILDREN</h4>

            <p>
              The Websites are intended for use by those over 18. We do not
              knowingly solicit, sell, share, or collect Personal Data on the
              Websites from children under the age of 18.
            </p>

            <h4>
              12. USE OF PERSONAL DATA IN RELATION TO DATA SUBJECTS IN CANADA
            </h4>

            <p>
              Canadian Anti-Spam Legislation requires that commercial electronic
              messages (&quot;CEMs&quot;) sent within Canada or into Canada from
              another jurisdiction may only be sent to individuals who opt into
              receiving them, subject to certain exceptions. Such consent may be
              implicit, such as by engaging in a prior business relationship, or
              by virtue of having one&#39;s email address listed in a public
              directory. These anti-spam laws also require senders to include an
              opt-out function such as an &quot;unsubscribe&quot; mechanism
              within a CEM. You may opt-out of the collection or use of your
              information by Brookfield Renewable. In some cases, withdrawing
              your consent to the collection, use or disclosure of some or all
              of your personal information will prevent us from providing
              services to you. To opt-out, please contact us using the details
              set out below or use the opt-out facility provided in the CEM.
              Please note that in some cases Brookfield Renewable is permitted
              to send you certain CEMs after you opt out, such as where required
              to enforce legal rights.
            </p>

            <h4>13. QUESTIONS AND CONCERNS</h4>

            <p>
              If you have any questions or concerns about Brookfield
              Renewable&#39;s handling of your Personal Data, or about this
              Policy, please contact us using the contact information set out
              below.
            </p>

            <p>Department: Legal Regulatory</p>

            <p>
              Email:
              <a href="mailto: privacy@brookfieldrenewable.com"
                >privacy@brookfieldrenewable.com</a
              >
            </p>
            <p>Call: <a href="tel:+1 844-749-2387">+1 844-749-2387</a></p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  created() {
    document.title =
      "Data Protection & Privacy | SafeWaters by Brookfield Renewable US";
  },
  mounted() {
    if (this.$route.query.aboutOurAds != undefined) {
      document
        .getElementById("test-02")
        .scrollIntoView({ behavior: "smooth" }, false);
    }
    document.querySelector(".ads").addEventListener("click", function (event) {
      document.querySelector(".ot-sdk-show-settings").click();
    });

    document.querySelector(".ads1").addEventListener("click", function (event) {
      document.querySelector(".ot-sdk-show-settings").click();
    });
  },
  methods: {
    redirectToDataScreen() {
      document
        .getElementById("test-02")
        .scrollIntoView({ behavior: "smooth" }, false);
    },
    navigationHandler() {
      this.$router.push({
        path: "terms",
      });
    },
  },
};
</script>

<style type="text/css">
.listtext {
  list-style-type: none;
}
.listtext li {
  margin-bottom: 10px;
}
.listtext li a {
  line-height: 125.18%;
  color: #0f3557;
  font-weight: 700;
}
.listtext li a:hover,
.listtext li a.active {
  color: #509e2f;
}
.showAd {
  cursor: pointer;
  color: #007bff;
}
</style>
